import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import api from "../../apis";
import SyncProducts from "./syunc_products";
import BankDetails from "./bankdetails";
import edit from "../../images/delete.svg";
import "./accounting.css";
import Loader from "../loader";

export default function Nozel({ Expenseshow, expensehandleClose }) {
  const user = useSelector((state) => state.authedUser.authedUser);
  console.log(user);

  const [productshow, setProductshow] = useState(false);

  const [bankshow, setBankshow] = useState(false);

  const bankhandleClose = () => {
    setBankshow(false);
  };

  const synchandleClose = () => {
    setProductshow(false);
  };

  const productmodule = () => {
    expensehandleClose();
    setProductshow(true);
  };

  const [formValues, setFormValues] = useState([{ amount: "", expense: "" }]);
  const [products, setProducts] = useState([]);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;

    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { amount: "", expense: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let handleSubmit = async (event) => {
    // alert(JSON.stringify(formValues));
    try {
      event.preventDefault();
      const payload = {
        formValues,
      };
      console.log(payload);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Modal show={Expenseshow} onHide={expensehandleClose} centered={true}>
        <Modal.Body>
          <div className="modalForm br-10 ">
            <div className="closeIco" onClick={expensehandleClose}>
              <i className="bi bi-x"></i>
            </div>
            <h3 className="">Add Expenses</h3>
            <button
              class=" w-25 btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 ms-auto"
              onClick={() => addFormFields()}
            >
              Add
            </button>

            <form onSubmit={handleSubmit}>
              {formValues.map((element, index) => (
                <div className="form-inline" key={index}>
                  <div className="NozelContainer ">
                    {index ? (
                      <img
                        onClick={() => removeFormFields(index)}
                        className="delete"
                        src={edit}
                        alt=""
                      />
                    ) : null}
                    <br />
                    <div>
                      <select
                        onChange={(e) => handleChange(index, e)}
                        className="form-control border-0 selectionProduct theme-bg-color"
                        name="expense"
                        id=""
                      >
                        <option value="" selected disabled>
                          {" "}
                          Select Expense{" "}
                        </option>
                        <option value="expense">XYZ</option>
                        <option value="expense">XYZ</option>
                        <option value="expense">XYZ</option>
                        <option value="expense">XYZ</option>
                      </select>
                      <input
                        onChange={(e) => handleChange(index, e)}
                        type="text"
                        className="input2 form-control border-0 small theme-bg-color"
                        id="edit-pump-name"
                        aria-describedby="emailHelp"
                        name="amount"
                        placeholder="Enter Amount  *"
                      />
                    </div>
                  </div>
                </div>
              ))}
              <div className="text-center">
                <button
                  type="submit"
                  class="w-25 mt-5 btn border-transparent loadBtnColor loadBtnBg justify-content-center font-weight-bold br10"
                  // onClick={() => productmodule()}
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
