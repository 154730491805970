import SiteNav from "../siteNavbar/siteNavbar";
import modalImg from "../../../images/modalImg.png";
import { useNavigate } from "react-router-dom";
import { Footer } from "../footer/footer";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
// import PropTypes from "prop-types";
import Loader from "../../loader";
import api from "../../../apis";
import helpers from "../../../helpers/helpers";
import cities from "./city.json";

export function CreateAccount() {
  const navigate = useNavigate();
  const [data, setData] = React.useState({
    email: "",
    name: "",
    omc: "",
    address: "",
    owner_phone: "",
    manager_phone: "",
    city: "",
    state: "",
  });
  const [loader, setLoader] = useState(false);

  const validator = () => {
    if (!data.email) {
      return "Email is required";
    }
    if (data.email && !helpers.isValidEmail(data.email)) {
      return "Email is not correctly formatted";
    }

    if (!data.address) {
      return "Address is required";
    }
    if (!data.name) {
      return "Name is required";
    }
    if (!data.omc) {
      return "OMC is required";
    }
    if (!data.owner_phone) {
      return "Phone No is required";
    }
    let ph = data.owner_phone.toString();
    if (ph[0] !== "0" && ph[1] !== "3") {
      return "Phone No must start from 03";
    }
    if (
      data.manager_phone.length != 6 &&
      data.manager_phone.length != 8 &&
      data.manager_phone.length != 11
    ) {
      return "PTCL is not correctly formatted";
    }
    if (data.owner_phone.length !== 11) {
      return "Phone No is not correctly formatted";
    }
    if (!data.manager_phone) {
      return "Mobile No is required";
    }
    if (!data.city) {
      return "City is required";
    }
    if (!data.state) {
      return "State is required";
    }

    return false;
  };
  const handleEmail = (event) => {
    setData({ ...data, email: event.target.value });
  };
  const handleName = (event) => {
    setData({ ...data, name: event.target.value });
  };
  const handleOmc = (event) => {
    setData({ ...data, omc: event.target.value });
  };

  const handleAddress = (event) => {
    setData({ ...data, address: event.target.value });
  };

  const handlePhone = (event) => {
    setData({ ...data, manager_phone: event.target.value });
  };
  const handleMobile = (event) => {
    setData({ ...data, owner_phone: event.target.value });
  };

  const handleCity = (event) => {
    setData({ ...data, city: event.target.value });
  };

  const handleState = (event) => {
    setData({ ...data, state: event.target.value });
  };

  const createAcccount = async (e) => {
    e.preventDefault();
    try {
      if (validator()) {
        throw new Error(validator());
      } else {
        function generateot() {
          var randomstring = "";
          var characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
          for (var i = 0; i < 40; i++) {
            randomstring += characters.charAt(
              Math.floor(Math.random() * characters.length)
            );
          }
          // console.log(randomstring);
          return randomstring;
        }
        var onetimepass = generateot();
        const payload = {
          email: data.email,
          type: "pump",
          address: data.address,
          omc: data.omc,
          name: data.name,
          owner_phone: data.owner_phone,
          manager_phone: data.manager_phone,
          city: data.city,
          state: data.state,
          otpass: onetimepass,
        };

        const response = await api.createAccount(payload);

        if (response.success == false) {
          throw new Error(response.message);
        }

        console.log(response);

        setData({});
        if (response.success == true) {
          swal({
            title: "Confirm Your Email",
            icon: "success",
          });
        }
      }
      setLoader(false);
      navigate("/login");
    } catch (e) {
      console.log(e);

      swal({
        title: e,
        icon: "warning",
      });
      setLoader(false);
    }
  };

  return (
    <div className="loginPage">
      <SiteNav />

      <div className="contactModal mt-3">
        <div className="container">
          <div className="row ai-center">
            <div className="col-lg-7">
              <div className="modalImg">
                <img className="img-fluid" src={modalImg} alt="pump" />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="mainContent">
                <div className="heading text-center p-0">
                  <h1>Create Account</h1>
                  <p className="mb-4">Find out more about pumpify.</p>
                </div>
                <div className="row mx-0">
                  <div className="col-md-12">
                    <form>
                      <div className="form-group mb-2">
                        <label htmlFor="Name">Name *</label>
                        <input
                          defaultValue={data.name}
                          onChange={handleName}
                          name="name"
                          type="text"
                          className="form-control theme-bg-color "
                          id="exampleInputPassword1"
                          placeholder="Your Name"
                        />
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor="exampleInputEmail1">
                          Email address *
                        </label>
                        <input
                          onChange={handleEmail}
                          name="email"
                          type="email"
                          className="form-control theme-bg-color "
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Your email address"
                        />
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor="Name">Oil Marketing Company *</label>
                        <input
                          onChange={handleOmc}
                          name="name"
                          type="text"
                          className="form-control theme-bg-color "
                          placeholder="Company"
                        />
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor="Name">Address *</label>
                        <input
                          onChange={handleAddress}
                          name="name"
                          type="text"
                          className="form-control theme-bg-color "
                          placeholder="Address"
                        />
                      </div>
                      <div className="d-flex">
                        <div className="form-group w-100 mb-2 me-2">
                          <label htmlFor="Name">PTCL *</label>
                          <input
                            onChange={handlePhone}
                            name="name"
                            type="text"
                            className="form-control theme-bg-color "
                            placeholder="PTCL Number"
                          />
                        </div>
                        <div className="form-group w-100 mb-2">
                          <label htmlFor="Name">Contact No *</label>
                          <input
                            onChange={handleMobile}
                            name="name"
                            type="text"
                            className="form-control theme-bg-color "
                            placeholder="Contact Number"
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-group w-100 mb-2 me-2">
                          <label htmlFor="Name">City *</label>
                          <select
                            onChange={handleCity}
                            className="form-control theme-bg-color border-0 "
                            name=""
                            id=""
                          >
                            <option value="" selected disabled>
                              Select City
                            </option>
                            {typeof cities !== "string" &&
                              cities.map((item) => (
                                <option value={item.city_name[0]}>
                                  {item.city_name[0]}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div className="form-group w-100 mb-2">
                          <label htmlFor="Name">State *</label>
                          <select
                            onChange={handleState}
                            className="form-control theme-bg-color border-0 "
                            name=""
                            id=""
                          >
                            <option value="" selected disabled>
                              Select State
                            </option>

                            <option value="sindh">Sindh</option>
                            <option value="baclochistan">Balochistan</option>
                            <option value="punjab">Punjab</option>
                            <option value="kpk">Khyber Pakhtunkhwa</option>
                          </select>
                        </div>
                      </div>

                      {/*                                     
                                    <div className="form-group mb-4">
                                        <label htmlFor="exampleInputEmail1">Message *</label>
                                        <textarea name="message" type="text" className="form-control" placeholder="Write message here*" rows={4} />
                                    </div> */}
                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn sendBtn"
                          onClick={createAcccount}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
