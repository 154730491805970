import React, { useState } from "react";
import SiteNav from "../siteNavbar/siteNavbar";
import modalImg from "../../../images/modalImg.png";
import { useNavigate, Navigate, NavLink, useLocation } from "react-router-dom";

import Loader from "../../loader";
import swal from "sweetalert";
import api from "../../../apis";
import { useEffect } from "react";

export function SetPassword() {
  const [userData, setUserData] = useState({});

  const [loader, setLoader] = useState(false);
  const { search } = useLocation();
  const navigate = useNavigate();

  const postdata = async (e) => {
    e.preventDefault();
    const onetimepass = new URLSearchParams(search).get("onetimepass");
    const userid = new URLSearchParams(search).get("user_id");
    var pass = document.getElementById("pwd").value;
    var cpass = document.getElementById("cpwd").value;

    if (!pass || !cpass) {
      // window.alert("password mismatched")
      swal({
        title: "PASSWORD IS NULL !",
        // text: "You clicked the button!",
        icon: "error",
      });

      return;
    }

    if (!onetimepass || !userid) {
      // window.alert("password mismatched")
      swal({
        title: "You're Not Authenticated!",
        // text: "You clicked the button!",
        icon: "error",
      });

      return;
    }

    if (pass != cpass) {
      // window.alert("password mismatched")
      swal({
        title: "PASSWORD MISMATCHED !",
        // text: "You clicked the button!",
        icon: "error",
      });

      return;
    } else {
      const dataPayload = {
        password: pass,
        otp: onetimepass,
        _id: userid,
      };
      const resp = await api.setpwd(dataPayload);
      console.log(resp);
      console.log("Helloooooo");
      console.log("Reacheddd here");
      swal({
        title: "SUCCESS !",
        // text: "You clicked the button!",
        icon: "success",
      });
      navigate("/login");
    }
  };

  const checkAlredyPassSet = async () => {
    try {
      const userid = new URLSearchParams(search).get("user_id");
      const dataPayload = {
        _id: userid,
      };
      const resp = await api.setpwdalready(dataPayload);
      console.log(resp.message[0]);
      setUserData(resp.message[0]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    checkAlredyPassSet();
  }, []);

  return (
    <div className="loginPage">
      <SiteNav />
      <div className="contactModal">
        {userData?._id && (
          <div className="container">
            {userData?.password ? (
              <h1 className="text-center">Password Already Set</h1>
            ) : (
              <div className="row ai-center">
                <div className="col-lg-7">
                  <div className="modalImg">
                    <img className="img-fluid" src={modalImg} alt="pump" />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="mainContent">
                    <div className="heading text-center p-0">
                      <h1>Set Password</h1>
                      <p className="mb-4">Find out more about pumpify.</p>
                    </div>
                    <div className="row mx-0">
                      <div className="col-md-12">
                        <form>
                          <div className="form-group mb-3">
                            <label htmlFor="pwd">New Password *</label>
                            <input
                              name="email"
                              type="password"
                              className="form-control theme-bg-color "
                              id="pwd"
                              aria-describedby="emailHelp"
                              placeholder="New Password"
                            />

                            <label htmlFor="cpwd">Confirm New Password *</label>
                            <input
                              name="email"
                              type="password"
                              className="form-control theme-bg-color "
                              id="cpwd"
                              aria-describedby="emailHelp"
                              placeholder="Confirm Password"
                            />
                          </div>

                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn sendBtn"
                              onClick={postdata}
                            >
                              Set Password
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {loader && <Loader />}
    </div>
  );
}
