import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Loader from "../loader";
import api from "../../apis";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import HRimage from "../imageupload/hr";

export default function Add({ show, setShow, fetchData }) {
  const user = useSelector((state) => state.authedUser.authedUser);

  const [imgdata, setImgdata] = useState({});
  const [loader, setLoader] = useState(false);
  const [pumps, setPumps] = useState([]);
  const [data, setData] = useState({
    cnic: "",
    name: "",
    description: "",
    phone: "",
    stolen: "",
    pump: "",
  });

  const fetchPumps = async () => {
    try {
      setLoader(true);
      const response = await api.getpumpsforcompany({ type: "pump" });
      console.log(response);
      if (Array.isArray(response.message)) setPumps(response.message);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (user.type === "pump") {
      setData({
        ...data,
        pump: user._id,
      });
    } else {
      fetchPumps();
    }
  }, []);

  const validator = () => {
    if (!data.name) {
      return "Name is required";
    }
    if (!data.description) {
      return "Description is required";
    }
    if (!data.cnic) {
      return "CNIC is required";
    }
    if (data.cnic.length !== 13) {
      return "CNIC is not correctly formatted";
    }
    if (!data.pump) {
      return "Pump is required";
    }
    if (!data.phone) {
      return "Phone is required";
    }

    return false;
  };

  const handlePumpChange = (event) => {
    setData({ ...data, pump: event.target.value });
  };

  const handlePhoneChange = (event) => {
    setData({ ...data, phone: event.target.value });
  };

  const handleCNICChange = (event) => {
    setData({ ...data, cnic: event.target.value });
  };
  const handleNameChange = (event) => {
    setData({ ...data, name: event.target.value });
  };

  const handleDescChange = (event) => {
    setData({ ...data, description: event.target.value });
  };
  const handleStolenChange = (event) => {
    setData({ ...data, stolen: event.target.value });
  };

  const addfunc = async () => {
    try {
      setLoader(true);
      if (validator()) {
        throw new Error(validator());
      } else {
        if (localStorage.getItem("token")) {
          const payloadd = {
            cnic: data.cnic,
            name: data.name,
            description: data.description,
            phone: data.phone,
            stolen: data.stolen,
            pump: data.pump,
            profilePic: imgdata.hrimageData,
            profilePicFormat: imgdata.hrimageFormat,
          };
          console.log(payloadd);
          let response = await api.createHR(payloadd);
          console.log(response);

          if (response.success) {
            fetchData();
            //alert("Registeration Successfull.");
            swal({
              title: "ADDED SUCCESSFULLY !",
              // text: "You clicked the button!",
              icon: "success",
            });
            setShow(false);
            setData({});
          }
        }
      }
      setLoader(false);
    } catch (e) {
      console.log(e);
      // alert(e);
      swal({
        title: e,

        icon: "warning",
      });
      setLoader(false);
    }
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} centered={true}>
      <Modal.Body>
        <div className="modalForm">
          <div className="closeIco" onClick={() => setShow(false)}>
            <i className="bi bi-x"></i>
          </div>
          <h3 className="">Add New HR</h3>
          <div className="form-group">
            <label>
              CNIC: <small>(13 characters) *</small>
            </label>
            <input
              type="number"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter CNIC"
              onChange={handleCNICChange}
            />
          </div>
          <div className="form-group">
            <label>Name *</label>
            <input
              type="text"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Name"
              onChange={handleNameChange}
            />
          </div>
          <div className="form-group">
            <label>Description *</label>
            <input
              type="text"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Description"
              onChange={handleDescChange}
            />
          </div>
          <div className="form-group">
            <label>Phone *</label>
            <input
              type="number"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Phone"
              onChange={handlePhoneChange}
            />
          </div>
          <div className="form-group">
            <label>Stolen Amount *</label>
            <input
              type="number"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Stolen Amount"
              onChange={handleStolenChange}
            />
          </div>

          {user.type === "admin" ? (
            <div className="form-group">
              <label>pump *</label>
              <select
                className="form-control theme-bg-color border-0 "
                name=""
                id=""
                onChange={handlePumpChange}
              >
                <option value="" disabled>
                  Select pump
                </option>
                {pumps.map((pump) => (
                  <option value={pump._id}>{pump.name}</option>
                ))}
              </select>
            </div>
          ) : (
            <></>
          )}

          <div className="form-group">
            <label>Upload Image *</label>
            <HRimage setImgdata={setImgdata} />
          </div>

          <div className="text-center subBtn">
            <button
              class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10"
              onClick={addfunc}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal.Body>
      {loader && <Loader />}
    </Modal>
  );
}
