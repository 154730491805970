import React, { useState } from "react";
import { useSelector } from "react-redux";
import api from "../../apis";
import CompanyDetailModal from "./companyDetailModal";
import CompanyEditModal from "./companyEditModal";
import swal from "sweetalert";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

import edit from "../../images/edit.svg";

import moreIcon from "../../images/moreIcon.svg";
import TempLimitEditModal from "./templimitModal";

export default function CompanyRow({
  pumps,
  item,
  setLoader,
  fetchallcompanies,
  updateCompany,
}) {
  const authedUser = useSelector((state) => state.authedUser);

  const user = authedUser.authedUser;

  const [editshow, seteditShow] = useState(false);
  const [editLimitShow, setEditLimitShow] = useState(false);

  const [detailshow, setdetailShow] = useState(false);
  const detailhandleClose = () => setdetailShow(false);

  const detailhandleShow = (param) => {
    setdetailShow(true);
  };

  const edithandleClose = () => seteditShow(false);

  const edithandleShow = (param) => {
    seteditShow(true);
  };

  const changestatus = async (item) => {
    try {
      if (user.type == "admin" || (user.type == "pump" && user.canUseAll)) {
        setLoader(true);
        let data = item;
        data.status = !data.status;
        const response = await api.blockcompany({
          user: {
            _id: item._id,
          },
          update: item,
        });
        console.log("item", item._id);
        fetchallcompanies();
        setLoader(false);
      }
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const deleteUser = async (item) => {
    try {
      if (user.type == "admin" || (user.type == "pump" && user.canUseAll)) {
        setLoader(true);
        let data = {
          _id: item._id,
        };
        console.log(data);
        const response = await api.deletecompany(data);
        if (!response) {
          throw new Error("Error while deleting");
        }
        swal({
          title: "DELETED SUCCESSFULLY !",
          icon: "success",
        });

        setLoader(false);
      } else {
        swal({
          title: "You are blocked due to some reason contact admin",

          icon: "warning",
        });
      }
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  return (
    <tr>
      <td className="d-flex align-items-center">
        <span className="ms-3 mt-2">{item.email}</span>
      </td>
      <td>{item.name}</td>
      <td>{item.address}</td>
      <td>{item.phone}</td>
      <td>{item.status ? "Not-Blocked" : "Blocked"}</td>
      <td>{item.consumed}</td>
      <td>{item?.totalLimit}</td>
      <td>{item.temp_limit}</td>
      <td>{item.consumedTempLimit}</td>
      <td>
        <img onClick={() => edithandleShow(item)} src={edit} alt="edit" />
      </td>
      <td>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button {...bindTrigger(popupState)}>
                <img src={moreIcon} alt="" />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem
                  onClick={() => {
                    popupState.close();
                    setEditLimitShow(true);
                  }}
                >
                  Temp limit
                </MenuItem>
                <MenuItem onClick={() => detailhandleShow(item)}>
                  Details
                </MenuItem>
                <MenuItem onClick={() => changestatus(item)}>
                  {item.status ? "Block" : "Un-Block"}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    deleteUser(item);
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </td>
      {detailshow ? (
        <CompanyDetailModal
          detailhandleClose={detailhandleClose}
          detailshow={detailshow}
          detailData={item}
        />
      ) : null}
      {editshow ? (
        <CompanyEditModal
          fetchallcompanies={fetchallcompanies}
          pumps={pumps}
          editshow={editshow}
          updateCompany={updateCompany}
          edithandleClose={edithandleClose}
          currentData={item}
          setLoader={setLoader}
        />
      ) : null}
      {editLimitShow ? (
        <TempLimitEditModal
          fetchallcompanies={fetchallcompanies}
          currentData={item}
          show={editLimitShow}
          updateCompany={updateCompany}
          setShow={setEditLimitShow}
        />
      ) : null}
    </tr>
  );
}
