import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Loader from "../loader";
import swal from "sweetalert";
import api from "../../apis";

export default function TempLimitEditModal({
  show,
  setShow,
  currentData,
  fetchallcompanies,
}) {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({
    temp_limit: "",
  });

  console.log("currentData", currentData);
  let thirty = [4, 6, 9, 11];
  let thirtyone = [1, 3, 5, 7, 8, 10, 12];
  let tweight = [2];

  const getEndDate = () => {
    const currentMonth = new Date().getMonth() + 1;
    let monthOf,
      endDate,
      date = new Date();

    if (currentData.billing_cycle === "Weekly") {
      date.setDate(date.getDate() + 7);
      endDate = date;
    }
    if (currentData.billing_cycle === "Bi-Weekly") {
      date.setDate(date.getDate() + 14);
      endDate = date;
    }
    if (currentData.billing_cycle === "Monthly" && thirty) {
      if (thirty.includes(currentMonth)) {
        monthOf = 30;
      }
      if (thirtyone.includes(currentMonth)) {
        monthOf = 31;
      }
      if (tweight.includes(currentMonth)) {
        monthOf = 28;
      }
      date.setDate(date.getDate() + monthOf);
      endDate = date;
    }
    return endDate;
  };

  const updateTempLimit = async () => {
    try {
      setLoader(true);

      console.log(currentData);
      let newTempLimit = document.getElementById("edit-temp-limit").value;

      if (localStorage.getItem("token")) {
        if (!newTempLimit) {
          swal({
            title: "Invalid temp limit value",
            icon: "error",
          });
        } else {
          newTempLimit = parseInt(newTempLimit);
          const start = new Date().toLocaleString();
          const end = new Date(getEndDate()).toLocaleString();

          if (
            parseInt(data.temp_limit) <= parseInt(currentData.consumedTempLimit)
          ) {
            throw new Error("Temp Limit is less than consumed temp limit");
          }

          let response = await api.update_temp({
            _id: currentData._id,
            temp: newTempLimit,
            tempstartDate: start,
            tempendDate: end,
          });

          // let response = await update_temp({
          //   _id: data._id,
          //   temp: data.temp_limit,
          //   tempstartDate: start,
          //   tempendDate: end,
          // });

          if (!response.success) {
            throw new Error(response.message);
          }
          fetchallcompanies();
          setData({});
          // alert("Temp Limit Update Sucessfully");
          swal({
            title: "TEMP LIMIT UPDATED SUCCESSFULLY !",
            // text: "You clicked the button!",
            icon: "success",
          });
          setShow(false);
        }
      } else {
        swal({
          title: "Unauthorized",
          icon: "error",
        });
      }
      // window.location.reload();
      setLoader(false);
    } catch (e) {
      console.log(e);
      // alert(e);
      swal({
        title: e,

        icon: "warning",
      });
      setLoader(false);
    }
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} centered={true}>
      <Modal.Body>
        <div className="modalForm">
          <div className="closeIco" onClick={() => setShow(false)}>
            <i className="bi bi-x"></i>
          </div>
          <h3 className="">Edit Temp Limit</h3>
          <div className="form-group">
            <label htmlFor="edit-temp-limit">Temp Limit</label>
            <input
              defaultValue={0}
              type="number"
              className="form-control theme-bg-color border-0 small"
              id="edit-temp-limit"
              aria-describedby="emailHelp"
              placeholder="Enter Temp Limit"
            />
          </div>

          <div className="text-center subBtn">
            <button
              onClick={updateTempLimit}
              class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10"
            >
              Submit
            </button>
          </div>
        </div>
      </Modal.Body>
      {loader && <Loader />}
    </Modal>
  );
}
