import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Modal from "react-bootstrap/Modal";
import Loader from "../loader";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import api from "../../apis/index";
import Row from "./row";
import searchIcon from "../../images/search.svg";

function Pump() {
  const authedUser = useSelector((state) => state.authedUser);

  const user = authedUser.authedUser;
  const [show, setShow] = useState(false);
  const [products, setProducts] = useState([]);

  const [selectedValue, setSelectedValue] = React.useState("a");
  const [loader, setLoader] = useState(false);
  const [productFilter, setProductFilter] = useState("");
  const [inventory, setInventory] = useState([]);

  const [data, setData] = React.useState({
    pump: "",
    product: "",
    rate: "",
    quantity: "",
  });

  useEffect(() => {
    if (user.type === "admin") {
      fetchAllpumps();
    }
  }, []);
  useEffect(() => {
    if (user.type === "admin") {
      if (data.pump !== "") {
        fetchAllProduct();
      }
    }
  }, [data.pump]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const [pumps, setPumps] = useState([]);

  const validator = () => {
    if (!data.rate) {
      return "Rate is required";
    }
    if (data.rate && isNaN(data.rate)) {
      return "Rate is not correctly formatted";
    }
    if (!data.pump) {
      return "Pump is required";
    }
    if (!data.product) {
      return "Product is required";
    }

    return false;
  };

  const fetchAllpumps = async () => {
    try {
      setLoader(true);
      const response = await api.getpumpsforcompany({ type: "pump" });
      if (response.success) {
        setPumps(response.message);
        setData({
          ...data,
          pump: response.message[0]._id,
        });
      }
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const fetchAllProduct = async () => {
    try {
      setLoader(true);
      if (user.type == "pump") {
        const response = await api.readproducts({ pump: user?._id });
        console.log({ pump: user?._id }, response);
        if (response.success) {
          setProducts(response.message);
          setData({
            ...data,
            pump: user?._id,
            product: response.message[0]?._id,
          });
        }
      } else {
        const response = await api.readproducts({ pump: data.pump });
        if (response.success) {
          setProducts(response.message);
          setData({
            ...data,
            product: response.message[0]?._id,
          });
        }
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const handlepumpChange = (event) => {
    setData({ ...data, pump: event.target.value });
  };

  const handleproductChange = (event) => {
    setData({ ...data, product: event.target.value });
  };

  const handleRateChange = (event) => {
    setData({ ...data, rate: event.target.value });
  };

  const handleQuantityChange = (event) => {
    setData({ ...data, quantity: event.target.value });
  };

  const addfunc = async () => {
    try {
      if (user.type == "admin" || (user.type == "pump" && user.canUseAll)) {
        setLoader(true);
        if (validator()) {
          throw new Error(validator());
        } else {
          if (localStorage.getItem("token")) {
            data.rate = parseFloat(data.rate);
            data.quantity = parseFloat(data.quantity);
            const payload = {
              pumpid: data.pump,
              productid: data.product,
              rate: data.rate,
              quantity: data.quantity,
            };

            let response = await api.createinventory(payload);

            if (!response) {
              throw new Error("Error while updating product");
            }
            fetchLatest();
            handleClose();
            //alert("Product Update Successfull.");
            swal({
              title: "INVENTORY ADDED SUCCESSFULLY !",
              // text: "You clicked the button!",
              icon: "success",
            });
          }
        }

        setLoader(false);
      } else {
        swal({
          title: "You are blocked due to some reason contact admin",

          icon: "warning",
        });
      }
    } catch (e) {
      console.log(e);
      // alert(e);
      swal({
        title: e,

        icon: "warning",
      });
      setLoader(false);
    }
  };

  const fetchLatest = () => {
    if (user.type === "pump") {
      // fetchAllUsersbypump();
      fetchAllInventorybypump();
    } else {
      fetchAllInventory();
    }
  };

  useEffect(() => {
    fetchLatest();
    if (user.type === "pump") {
      fetchAllProduct();
    }
  }, []);

  const fetchAllInventory = async () => {
    try {
      setLoader(true);
      const response = await api.readinventory({
        type: "inventory",
      });
      setInventory(response.message);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const fetchAllInventorybypump = async () => {
    try {
      setLoader(true);

      const data = {
        _id: user._id,
      };
      const response = await api.getinventory(data);
      setInventory(response.message);

      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
        {/* Content Row */}
        <div className="row">
          {/* Area Chart */}
          <div className="col ">
            <div className=" card sortby-box br-10 border-transparent">
              {/* Card Header - Dropdown */}
              <h6 className="mt-1 ml-5">Filters</h6>
              <div className="card-header br-10  bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
                <form className="d-none d-sm-inline-block form-inline me-auto ms-md-3 my-2 my-md-0 mw-100 navbar-search">
                  <div className="input-group">
                    {/* <input type="text" className="form-control theme-bg-color border-0 small" placeholder="Enter Name" aria-label="Search" aria-describedby="basic-addon2" /> */}
                    <select
                      onChange={handlepumpChange}
                      className="form-control theme-bg-color border-0 "
                      name=""
                      id=""
                    >
                      <option value="" disabled>
                        Select pump
                      </option>
                      {typeof pumps !== "string" &&
                        pumps.map((item) => (
                          <option value={item._id}>
                            {item.name}({item.email})
                          </option>
                        ))}
                    </select>
                    <div className="input-group-append">
                      <button className="btn search-btn pr-4" type="button">
                        <img
                          src={searchIcon}
                          className="w-15 mb-1"
                          alt="serach icon"
                        />
                      </button>
                    </div>
                  </div>
                </form>
                <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                  <div className="mr-3 input-group">
                    <input
                      type="text"
                      className="form-control theme-bg-color border-0 small"
                      placeholder="Enter Product Name"
                      onChange={(e) => setProductFilter(e.target.value)}
                      value={productFilter}
                    />

                    {/* <select
                      onChange={handleproductChange}
                      className="form-control theme-bg-color border-0 "
                      name=""
                      id=""
                    >
                      <option value="" disabled>
                        Select product
                      </option>
                      {typeof products !== "string" &&
                        products.map((item) => (
                          <option value={item._id}>{item.name}</option>
                        ))}
                    </select> */}
                    <div className="input-group-append">
                      <button className="btn search-btn pr-4" type="button">
                        <img
                          src={searchIcon}
                          className="w-15 mb-1"
                          alt="serach icon"
                        />
                      </button>
                    </div>
                  </div>
                </form>
                <a
                  className=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 fs-12"
                  onClick={() => handleShow()}
                >
                  <span className="fs-14">+</span> Add New Inventory
                </a>
              </div>
            </div>
            {/* table */}
            <Table
              fetchLatest={fetchLatest}
              productFilter={productFilter}
              inventory={inventory}
            />
          </div>

          {/* post load / bid column*/}
          {/* <LoadSecondRow /> */}
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered={true}>
        <Modal.Body>
          <div className="modalForm">
            <div className="closeIco" onClick={handleClose}>
              <i className="bi bi-x"></i>
            </div>
            <h3 className="">Add New Inventory</h3>
            {user.type === "admin" ? (
              <div className="form-group">
                <label>pump</label>
                <select
                  onChange={handlepumpChange}
                  className="form-control theme-bg-color border-0 "
                  name=""
                  id=""
                >
                  <option value="" disabled>
                    Select pump
                  </option>
                  {typeof pumps !== "string" &&
                    pumps.map((item) => (
                      <option value={item._id}>
                        {item.name}({item.email})
                      </option>
                    ))}
                </select>
              </div>
            ) : (
              <></>
            )}
            <div className="form-group">
              <label>Enter Product *</label>
              <select
                onChange={handleproductChange}
                className="form-control theme-bg-color border-0 "
                name=""
                id=""
              >
                <option value="" disabled>
                  Select product
                </option>
                {typeof products !== "string" &&
                  products.map((item) => (
                    <option value={item._id}>{item.name}</option>
                  ))}
              </select>
            </div>
            <div className="form-group">
              <label>Rate *</label>
              <input
                type="number"
                className="form-control theme-bg-color border-0 small"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Rate"
                onChange={handleRateChange}
              />
            </div>
            <div className="form-group">
              <label>Quantity *</label>
              <input
                type="number"
                className="form-control theme-bg-color border-0 small"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Quantity"
                onChange={handleQuantityChange}
              />
            </div>

            <div className="text-center subBtn">
              <button
                class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10"
                onClick={addfunc}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* /.container-fluid */}
      {loader && <Loader />}
    </>
  );
}

const Table = ({ inventory, fetchLatest, productFilter }) => {
  const authedUser = useSelector((state) => state.authedUser);

  const user = authedUser.authedUser;

  // const [producst, setProducts] = useState([]);
  const [loader, setLoader] = useState(false);

  const itemsperpages = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(inventory.length / itemsperpages);

  const itemsInCurrentPage = inventory
    .filter((i) =>
      i?.productid?.name?.toLowerCase()?.includes(productFilter.toLowerCase())
    )
    .slice((currentPage - 1) * itemsperpages, currentPage * itemsperpages);

  return (
    <div className="card sortby-box br-10 border-transparent mt-3">
      <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 d-flex flex-column align-items-center justify-content-between">
        <table class="table table-hover">
          <thead>
            <tr>
              <th className=" pl-4">Product</th>
              <th className="">Rate</th>
              <th className="">Qunatity</th>
              {/* <th className="">Available</th> */}
              {/* <th className="">Unit</th> */}
              {authedUser.authedUser.type === "admin" ? (
                <th className="">Pump</th>
              ) : (
                <></>
              )}
              <th className="">Edit</th>
              <th className="">Other options</th>
            </tr>
          </thead>
          <tbody>
            {typeof itemsInCurrentPage !== "string" &&
              itemsInCurrentPage.map((item) => (
                <Row
                  item={item}
                  fetchData={fetchLatest}
                  setLoader={setLoader}
                  key={uuidv4()}
                ></Row>
              ))}
          </tbody>
        </table>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                Prev
              </button>
            </li>
            {[...Array(totalPages)].map((_, index) => (
              <li
                key={index + 1}
                className={`page-item ${
                  currentPage === index + 1 ? "active" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => setCurrentPage(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>

      {/* <EditModal open={editModal} setOpen={closeModal} /> */}

      {loader && <Loader />}
    </div>
  );
};

export default Pump;
