import React, { useEffect, useState } from "react";
import api from "../../apis/index";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import NozelData from "./nozeldata";
import SyncProducts from "./syunc_products";
import BankDetails from "./bankdetails";
import "./accounting.css";

import edit from "../../images/delete.svg";

function Pump({ editpaymentshow, EditpaymenthandleClose }) {
  const authedUser = useSelector((state) => state.authedUser);
  const user = authedUser.authedUser;

  const [formValues, setFormValues] = useState(user?.virtualPayments);

  const options = ["pso card", "shell card"];
  const [newoption, setNewoption] = useState(options);

  let handleChange = (i, e) => {
    console.log("i", i);
    console.log("e", e);
    let newFormValues = [...formValues];
    newFormValues[i] = e.target.value;
    setFormValues(newFormValues);
  };

  const addoption = () => {
    let payoption = document.getElementById("otheroptions").value;
    options.push(payoption);
    setNewoption(options);
    console.log(options);
  };

  let addFormFields = () => {
    setFormValues([...formValues, {}]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let handleSubmit = async (event) => {
    alert(JSON.stringify(formValues));
    console.log(formValues);
    try {
      event.preventDefault();
      const payload = {
        pump: user?._id,
        virtualPayments: formValues,
      };
      const response = await api.Editvirtualpay(payload);
      if (response.success) {
        swal({
          title: response.message,
          icon: "success",
        });
        // nozelmodule();
      }
    } catch (e) {
      console.log(e);
      swal({
        title: e,
        icon: "error",
      });
    }
  };

  return (
    <>
      <Modal
        show={editpaymentshow}
        onHide={EditpaymenthandleClose}
        centered={true}
      >
        <Modal.Body>
          <div className="modalForm br-10 methodcontainer">
            <div className="closeIco" onClick={EditpaymenthandleClose}>
              <i className="bi bi-x"></i>
            </div>
            <h3 className="">Virtual Payments</h3>
            <button
              class=" w-25 btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 ms-auto"
              onClick={() => addFormFields()}
            >
              Add
            </button>
            <form onSubmit={handleSubmit}>
              {formValues.map((element, index) => (
                <div className="form-inline" key={index}>
                  <div className="paymentmethod ">
                    {index ? (
                      <img
                        onClick={() => removeFormFields(index)}
                        className="delete"
                        src={edit}
                        alt=""
                      />
                    ) : null}
                    <br />
                    <select
                      onChange={(e) => handleChange(index, e)}
                      className="form-control border-0 selection theme-bg-color"
                      name=""
                      id=""
                    >
                      <option value="" selected disabled>
                        {" "}
                        Select Payment Method{" "}
                      </option>
                      {typeof newoption !== "string" &&
                        newoption.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                    </select>
                  </div>
                </div>
              ))}

              <button
                type="submit"
                class="w-25 mt-5 btn border-transparent loadBtnColor loadBtnBg justify-content-center font-weight-bold br10"
                // onClick={() => nozelmodule()}
              >
                Save
              </button>
            </form>
            <div className="paymentmethodadd ">
              <br />
              <input
                type="text"
                className="inputadd me-2 form-control border-0 small theme-bg-color"
                id="otheroptions"
                aria-describedby="emailHelp"
                name=""
                placeholder="Other Option"
              />
              <button
                onClick={() => addoption()}
                class=" addbutton btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10"
              >
                Add
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Pump;
