import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import api from "../../apis";
import helpers from "../../helpers/helpers";
import Add from "./add";
import StaffRow from "./staffRow";

import searchIcon from "../../images/search.svg";

function Pump() {
  const [show, setShow] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState("a");
  const [staffs, setStaffs] = useState([]);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({});

  // const [open, setOpen] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const fetchAllUsers = async (filter = "") => {
    try {
      setLoader(true);
      let response;
      // if (filter) {
      //   response = await api.get_staff({
      //     type: "staff",

      //   });
      // } else {
      response = await api.get_staff({
        type: "staff",
        email: { $regex: filter, $options: "i" },
      });
      // }
      console.log(response?.message);
      if (response?.success && Array.isArray(response?.message)) {
        setStaffs(response.message);
      } else {
        setStaffs([]);
      }
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const validator = () => {
    if (!data.name) {
      return "Name is required";
    }
    if (!data.email) {
      return "Email is required";
    }
    if (data.email && !helpers.isValidEmail(data.email)) {
      return "Email is not correctly formatted";
    }

    if (!data.address) {
      return "Address is required";
    }
    if (!data.phone) {
      return "Phone is required";
    }
    if (data.phone && isNaN(data.phone)) {
      return "Phone is not correctly formatted";
    }
    let ph = data.phone.toString();

    if (ph[0] !== "0" || ph[1] !== "3") {
      return "Phone number must start from 03";
    }

    if (data.phone.length !== 11) {
      return "Phone is not correctly formatted";
    }
    if (!data.pump) {
      return "Pump is required";
    }
    if (!data.cnic) {
      return "CNIC is required";
    }
    if (data.cnic && isNaN(data.cnic)) {
      return "CNIC is not correctly formatted";
    }
    if (data.cnic.length !== 13) {
      return "CNIC is not correctly formatted";
    }

    return false;
  };

  const handlePumpChange = (event) => {
    setData({ ...data, pump: event.target.value });
  };

  const handleNameChange = (event) => {
    setData({ ...data, name: event.target.value });
  };

  const handleAddressChange = (event) => {
    setData({ ...data, address: event.target.value });
  };

  const handleEmailChange = (event) => {
    setData({ ...data, email: event.target.value });
  };

  const handlePhoneChange = (event) => {
    setData({ ...data, phone: event.target.value });
  };

  const handleCNICChange = (event) => {
    setData({ ...data, cnic: event.target.value });
  };

  const handleCheckChange = (event, name) => {
    // setData({ ...data, permission: event.target.value });
    data.permissions.push(event.target.value);
  };

  const filteredStaff = async () => {
    let filter = document.getElementById("staff-filter-email").value
      ? document.getElementById("staff-filter-email").value
      : "";
    fetchAllUsers(filter);
  };

  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
        {/* Content Row */}
        <div className="row">
          {/* Area Chart */}
          <div className="col ">
            <div className=" card sortby-box br-10 border-transparent">
              {/* Card Header - Dropdown */}
              <div className="card-header br-10  bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
                <form className="d-none d-sm-inline-block form-inline me-auto ms-md-3 my-2 my-md-0 mw-100 navbar-search">
                  <h6>Filters</h6>
                  <div className="input-group">
                    <input
                      type="text"
                      id="staff-filter-email"
                      className="form-control theme-bg-color border-0 small"
                      placeholder="Enter Email"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                    />
                    <div className="input-group-append">
                      <button
                        onClick={filteredStaff}
                        className="btn search-btn pr-4"
                        type="button"
                      >
                        <img
                          src={searchIcon}
                          className="w-15 mb-1"
                          alt="serach icon"
                        />
                      </button>
                    </div>
                  </div>
                </form>
                <a
                  className=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 fs-12"
                  onClick={() => handleShow()}
                >
                  <span className="fs-14">+</span> Add New Staff
                </a>
              </div>
            </div>
            {/* table */}
            <Table
              setLoader={setLoader}
              staffs={staffs}
              fetchData={fetchAllUsers}
            />
          </div>

          {/* post load / bid column*/}
          {/* <LoadSecondRow /> */}
        </div>
      </div>
      <Add open={show} setOpen={setShow} fetchData={fetchAllUsers} />
      {/* <Modal show={show} onHide={handleClose} centered={true}>
        <Modal.Body>
          <div className="modalForm">
            <h3 className=''>Add New Staff</h3>
            <div className="form-group">
              <label>Name</label>
              <input type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name" onChange={handleNameChange} />
            </div>
            <div className="form-group">
              <label>Email address</label>
              <input type="email" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email" onChange={handleEmailChange} />
              <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
            </div>
            <div className="form-group">
              <label>Address</label>
              <input type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address" onChange={handleAddressChange} />
            </div>
            <div className="form-group">
              <label>Phone</label>
              <input type="number" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Phone" onChange={handlePhoneChange} />
            </div>
            <div className="form-group">
              <label>CNIC</label>
              <input type="number" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter CNIC" onChange={handleCNICChange} />
            </div>
            <div className="form-group">
              <label>pump</label>
              <select className="form-control theme-bg-color border-0 " name="" id="" onChange={handlePumpChange}>
                <option value="" disabled>Select pump</option>
                <option value="1">two</option>
              </select>
            </div>
            <div className='text-center subBtn'>
              <button class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10" onClick={addfunc} >Submit</button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
      {/* /.container-fluid */}
    </>
  );
}

const Table = ({ staffs, fetchData, setLoader }) => {
  const [data, setData] = React.useState({
    name: "",
    address: "",
    email: "",
    pump: "",
    phone: "",
    permissions: [],
  });

  const validator = () => {
    if (!data.name) {
      return "Name is required";
    }
    if (!data.email) {
      return "Email is required";
    }
    // if (data.email && !helpers.isValidEmail(data.email)) {
    //   return "Email is not correctly formatted";
    // }

    if (!data.address) {
      return "Address is required";
    }
    if (!data.phone) {
      return "Phone is required";
    }
    if (data.phone && isNaN(data.phone)) {
      return "Phone is not correctly formatted";
    }
    let ph = data.phone.toString();

    if (ph[0] !== "0" || ph[1] !== "3") {
      return "Phone number must start from 03";
    }

    if (data.phone.length !== 11) {
      return "Phone is not correctly formatted";
    }
    if (!data.pump) {
      return "Pump is required";
    }
    // if (!data.password) {
    //   return "Password is required";
    // }
    // if (data.password.length < 6) {
    //   return "Password must be greater than 6 characters";
    // }
    if (!data.cnic) {
      return "CNIC is required";
    }
    if (data.cnic && isNaN(data.cnic)) {
      return "CNIC is not correctly formatted";
    }
    if (data.cnic.length !== 13) {
      return "CNIC is not correctly formatted";
    }

    return false;
  };

  return (
    <div className="card sortby-box br-10 border-transparent mt-3">
      <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Email</th>
              <th scope="col">Title</th>
              <th scope="col">Status</th>
              <th scope="col">Address</th>
              <th scope="col">Edit</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {staffs?.map((staff) => (
              <StaffRow
                fetchData={fetchData}
                setLoader={setLoader}
                item={staff}
                key={staff._id}
              />
            ))}
          </tbody>
        </table>
      </div>

      {/* <EditModal open={editModal} setOpen={closeModal} /> */}
    </div>
  );
};
const LoadSecondRow = () => {
  return (
    <div className="col-xl-4 col-lg-4 mb-3">
      <div className="card h-100 mb-4 br-10 border-transparent overflow-hidden">
        {/* Card Header - Dropdown */}
        <div className="card-header border-bootom-light bg-white  py-3 d-flex flex-row align-items-center justify-content-between">
          <div>
            <div className="color-dark fs-19 font-weight-bold">$1,850</div>
            <div className="color-light font-weight-bold fs-12">$2.78/mi</div>
          </div>
          <div>
            <div className="color-dark fs-19 font-weight-bold">664mi</div>
            <div className="color-light font-weight-bold fs-12">Mon 4/26</div>
          </div>
        </div>

        {/* Card header */}
        <div className="card-header border-bootom-light bg-white  py-3 ">
          {/* driver card row */}
          <div className="row py-2  px-0">
            <div className="col-auto">
              <div className="circle-light"></div>
              <div class="vertical-line-light m-auto"></div>
              <div className="circle"></div>
              <div class="vertical-line-dark m-auto"></div>
              <div className="circle-fill"></div>
            </div>
            <div className="col ">
              <p className="color-light fs-13 font-weight-600 mb-0">Deadhead</p>
              <div className="d-flex">
                <hr className="w-75 ms-0 border-top-light" />
                <p className="deadhead-mi m-auto">43 mi</p>
              </div>
              <div className="deadhead-title">Miami, FL</div>
              <div className="deadhead-time">Mon 4/26, 9:30 EST</div>
              <div className="d-flex">
                <hr className="w-75 ms-0 border-top-light" />
                <p className="deadhead-mi m-auto">664 mi</p>
              </div>
              <div className="deadhead-title">Atlanta, GA</div>
              <div className="deadhead-time">Tue 4/27, 17:30 - 19:30 EST</div>
            </div>
          </div>
        </div>

        <div className="card-header bg-white border-transparent pb-2 pt-3 d-flex flex-row align-items-center justify-content-between">
          <div>
            <div className="color-light font-weight-600 fs-10">
              Trailer Type
            </div>
            <div className="color-dark fs-17 font-weight-bold">Reefer</div>
          </div>
          <div>
            <div className="color-light font-weight-600 fs-10">
              Trailer Length
            </div>
            <div className="color-dark fs-17 font-weight-bold">53'</div>
          </div>
        </div>
        <div className="card-header bg-white border-transparent py-2 d-flex flex-row align-items-center justify-content-between">
          <div>
            <div className="color-light font-weight-600 fs-10">
              Full or partial
            </div>
            <div className="color-dark fs-17 font-weight-bold">Full</div>
          </div>
          <div>
            <div className="color-light font-weight-600 fs-10">WEIGHT</div>
            <div className="color-dark fs-17 font-weight-bold">35,000 lbs</div>
          </div>
        </div>
        <div className="card-header border-bootom-light bg-white border-transparent pt-2 pb-3">
          <div>
            <div className="color-light font-weight-600 fs-10">Requirement</div>
            <div className="color-dark fs-17 font-weight-bold">
              CARB, Temp control to 28 F
            </div>
          </div>
        </div>
        <div className="card-header bg-white border-transparent pt-3 pb-2">
          <div>
            <div className="color-light font-weight-600 fs-10">Commodity</div>
            <div className="color-dark fs-17 font-weight-bold">
              Refrigerated Produce
            </div>
          </div>
        </div>
        <div className="card-header border-bootom-light bg-white pt-2 pb-3">
          <div>
            <div className="color-light font-weight-600 fs-10 mb-1">
              Comment
            </div>
            <div className="color-light font-weight-600 fs-11">
              Driver will be required to wear safety vest while at the shippers
              facility
            </div>
          </div>
        </div>
        {/* bid and post button */}
        <div className="card-header bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
          <a
            href=""
            class=" btn border-transparent loadBtnColor-underline font-weight-bold fs-12 w-100"
          >
            Bid
          </a>
          <a
            href=""
            class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold fs-12 w-100 ml-2"
          >
            <span class="fs-14">+</span> Add New Load
          </a>
        </div>
      </div>
    </div>
  );
};
export default Pump;
