import React, { useEffect, useState } from "react";
import api from "../../apis/index";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import NozelData from "./nozeldata";
import SyncProducts from "./syunc_products";
import BankDetails from "./bankdetails";
import EditPay from "./editPayments";
import EditProduts from "./Editsyunc_products";
import EditBankDetails from "./editbankdetails";
import DailySheet from "./Dailysheet";
import ADS from "./ADS";
import Expenses from "./Expenses";
import "./accounting.css";

import edit from "../../images/delete.svg";

function Pump() {
  const authedUser = useSelector((state) => state.authedUser);
  const user = authedUser.authedUser;

  const [paymentshow, setPayemnetshow] = useState(false);
  const [pnl, setPnl] = useState({ month: "", year: "" });
  const [editpaymentshow, setEditPayemnetshow] = useState(false);

  const [nozelshow, setNozelshow] = useState(false);

  const [productshow, setProductshow] = useState(false);

  const [sheetshow, setSheetshow] = useState(false);

  const [Editproductshow, EditsetProductshow] = useState(false);

  const [bankshow, setBankshow] = useState(false);

  const [ADSshow, setADSshow] = useState(false);

  const [Expenseshow, setExpensehow] = useState(false);

  const [Editbankshow, EditsetBankshow] = useState(false);

  const EditpaymenthandleClose = () => {
    setEditPayemnetshow(false);
  };

  const paymenthandleClose = () => {
    setPayemnetshow(false);
  };

  const ADShandleClose = () => {
    setADSshow(false);
  };

  const expensehandleClose = () => {
    setExpensehow(false);
  };

  const nozelhandleClose = () => {
    setNozelshow(false);
  };

  const bankhandleClose = () => {
    setBankshow(false);
  };

  const sheethandleClose = () => {
    setSheetshow(false);
  };

  const EditbankhandleClose = () => {
    EditsetBankshow(false);
  };
  const synchandleClose = () => {
    setProductshow(false);
  };

  const EditsynchandleClose = () => {
    EditsetProductshow(false);
  };

  const Payemnetmodule = () => {
    // const authedUser = useSelector(state => state.authedUser);
    // const user = authedUser.authedUser;
    if (user?.step == 0) {
      setPayemnetshow(true);
    }
    if (user?.step == 1) {
      // nozelmodule()
      setNozelshow(true);
    }
    if (user?.step == 2) {
      setProductshow(true);
    }
    if (user?.step == 3) {
      // nozelmodule()
      setBankshow(true);
    }
  };
  const nozelmodule = () => {
    setPayemnetshow(false);
    setNozelshow(true);
  };

  const [formValues, setFormValues] = useState([{}]);

  const options = ["pso card", "shell card"];
  const [newoption, setNewoption] = useState(options);

  const handleyearChange = (event) => {
    setPnl({ ...pnl, year: event.target.value });
  };

  const handlemonthChange = (event) => {
    setPnl({ ...pnl, month: event.target.value });
  };

  let handleChange = (i, e) => {
    console.log("i", i);
    console.log("e", e);
    let newFormValues = [...formValues];
    newFormValues[i] = e.target.value;
    setFormValues(newFormValues);
  };

  const addoption = () => {
    let payoption = document.getElementById("otheroptions").value;
    options.push(payoption);
    setNewoption(options);
    console.log(options);
  };

  let addFormFields = () => {
    setFormValues([...formValues, {}]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let handleSubmit = async (event) => {
    alert(JSON.stringify(formValues));
    console.log(formValues);

    try {
      event.preventDefault();
      const payload = {
        pump: user?._id,
        virtualPayments: formValues,
      };

      const response = await api.virtualpay(payload);
      if (response.success) {
        swal({
          title: response.message,
          icon: "success",
        });
        nozelmodule();
      }
    } catch (e) {
      console.log(e);
      swal({
        title: e,
        icon: "error",
      });
    }
  };
  const showpnl = async () => {
    try {
      const createdDate = user?.Registerdate;
      let a = document.getElementById("acc-date").value;
      // const createdDate = user?.updatedAt;

      // const olddate = new Date(createdDate);
      // const oldyear = olddate.getFullYear();
      // const oldmonth = olddate.getMonth() + 1;
      // console.log(pnl, oldyear, oldmonth);
      // if (pnl.year >= oldyear) {

      //   if (pnl.month >= oldmonth) {
      //     const payload = {
      //       month: pnl.month,
      //       year: pnl.year
      //     }
      //     console.log(payload);
      //     let rr = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/read-nozels-start-end?pump=${user._id}&date=${payload.month}/${payload.year}`, {
      //       headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
      //     }).then(r => r.json());
      //     console.log(rr);

      //     let aa = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/read-daily-sheet-record?pump=${user._id}&date=${payload.month}/${payload.year}`, {
      //       headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
      //     }).then(r => r.json());
      //     console.log(aa);
      //   }
      // }
      // else {
      //   console.log("sorry");
      // }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <div className="container-fluid ">
        <div className="row">
          <div className="col ">
            <div className="card sortby-box br-10 border-transparent">
              <div className="col-xl-3 col-lg-4 mb-4 ">
                <div className="card h-100 mb-4 br-10 border-transparent overflow-hidden">
                  {user?.step < 4 && (
                    <>
                      <div className="card-header bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
                        <h1 className="m-0 diver-name">
                          Accounting is not configured yet !!
                        </h1>
                      </div>
                      <div className="card-body d-flex">
                        <button
                          class="btn border-transparent loadBtnColor loadBtnBg justify-content-center font-weight-bold br10"
                          onClick={() => Payemnetmodule()}
                        >
                          Configure Accounting
                        </button>
                      </div>
                    </>
                  )}
                  {user?.step == 4 && (
                    <div className="card-body d-flex flex-column">
                      <button class="btn border-transparent loadBtnColor loadBtnBg justify-content-center font-weight-bold br10">
                        Accounting Configured
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {user?.step > 0 && (
          <div className="mt-4 col-xl-3 col-lg-4 mb-4 ">
            <div className="card h-100 mb-4 br-10 border-transparent overflow-hidden">
              <div className="card-body d-flex flex-column">
                <button
                  class="btn border-transparent loadBtnColor loadBtnBg justify-content-center font-weight-bold br10"
                  onClick={() => setEditPayemnetshow(true)}
                >
                  Edit your Payments
                </button>
              </div>
            </div>
          </div>
        )}

        {user?.step > 1 && (
          <div className="col-xl-3 col-lg-4 mb-4 ">
            <div className="card h-100 mb-4 br-10 border-transparent overflow-hidden">
              <div className="card-body d-flex flex-column">
                <button
                  class="btn border-transparent loadBtnColor loadBtnBg justify-content-center font-weight-bold br10"
                  onClick={() => EditsetProductshow(true)}
                >
                  Sync Products
                </button>
              </div>
            </div>
          </div>
        )}

        {user?.step > 3 && (
          <div className="col-xl-3 col-lg-4 mb-4 ">
            <div className="card h-100 mb-4 br-10 border-transparent overflow-hidden">
              <div className="card-body d-flex flex-column">
                <button
                  class="btn border-transparent loadBtnColor loadBtnBg justify-content-center font-weight-bold br10"
                  onClick={() => EditsetBankshow(true)}
                >
                  Edit Bank Details
                </button>
              </div>
            </div>
          </div>
        )}

        {user?.step == 4 && (
          <div className="col-xl-3 col-lg-4 mb-4 ">
            <div className="card h-100 mb-4 br-10 border-transparent overflow-hidden">
              <div className="card-body d-flex flex-column">
                <button
                  class="btn border-transparent loadBtnColor loadBtnBg justify-content-center font-weight-bold br10"
                  onClick={() => setSheetshow(true)}
                >
                  Daily Sheet Management{" "}
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="col-xl-3 col-lg-4 mb-4 ">
          <div className="card h-100 mb-4 br-10 border-transparent overflow-hidden">
            <div className="card-body d-flex flex-column">
              <button
                class="btn border-transparent loadBtnColor loadBtnBg justify-content-center font-weight-bold br10"
                onClick={() => setADSshow(true)}
              >
                Add Product Sale{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 mb-4 ">
          <div className="card h-100 mb-4 br-10 border-transparent overflow-hidden">
            <div className="card-body d-flex flex-column">
              <button
                class="btn border-transparent loadBtnColor loadBtnBg justify-content-center font-weight-bold br10"
                onClick={() => setExpensehow(true)}
              >
                Add Expenses{" "}
              </button>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 mb-4 ">
          <div className="card h-100 mb-4 br-10 border-transparent overflow-hidden">
            <div className="card-body d-flex flex-column">
              <h4>Daily Accounting</h4>
              <div className="form-group">
                <input
                  type="date"
                  id="acc-date"
                  style={{ width: "100%", borderRadius: "5px" }}
                />
                {/* <select onChange={handlemonthChange} className="form-control theme-bg-color border-0 " name="" id="" >
                  <option value="" disabled selected>Select Month</option>
                  <option value="01">
                    January
                  </option>
                  <option value="02">
                    February
                  </option>
                  <option value="03">
                    March
                  </option>
                  <option value="04">
                    April
                  </option>
                  <option value="05">
                    May
                  </option>
                  <option value="06">
                    June
                  </option>
                  <option value="07">
                    July
                  </option>
                  <option value="08">
                    August
                  </option>
                  <option value="09">
                    September
                  </option>
                  <option value="10">
                    October
                  </option>
                  <option value="11">
                    November
                  </option>
                  <option value="12">
                    December
                  </option>
                </select> */}
              </div>
              {/* <div className="form-group">
                <select onChange={handleyearChange} className="form-control theme-bg-color border-0 " name="" id="" >
                  <option value="" disabled selected>Select Year</option>
                  <option value="2022">
                    2022
                  </option>
                  <option value="2023">
                    2023
                  </option>
                  <option value="2024">
                    2024
                  </option>
                  <option value="2025">
                    2025                </option>
                  <option value="2026">
                    2026
                  </option>
                </select>
              </div> */}
              <button
                class="btn border-transparent loadBtnColor loadBtnBg justify-content-center font-weight-bold br10"
                onClick={() => showpnl()}
              >
                Download Sheet{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={paymentshow} onHide={paymenthandleClose} centered={true}>
        <Modal.Body>
          <div className="modalForm br-10  methodcontainer">
            <div className="closeIco" onClick={paymenthandleClose}>
              <i className="bi bi-x"></i>
            </div>
            <h3 className="">Virtual Payments</h3>
            <button
              class=" w-25 btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 ms-auto"
              onClick={() => addFormFields()}
            >
              Add
            </button>
            <form onSubmit={handleSubmit}>
              {formValues.map((element, index) => (
                <div className="form-inline" key={index}>
                  <div className="paymentmethod ">
                    {index ? (
                      <img
                        onClick={() => removeFormFields(index)}
                        className="delete"
                        src={edit}
                        alt=""
                      />
                    ) : null}
                    <br />
                    <select
                      onChange={(e) => handleChange(index, e)}
                      className="form-control border-0 selection theme-bg-color"
                      name=""
                      id=""
                    >
                      <option value="" selected disabled>
                        {" "}
                        Select Payment Method{" "}
                      </option>
                      {typeof newoption !== "string" &&
                        newoption.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                    </select>
                  </div>
                </div>
              ))}

              <button
                type="submit"
                class="w-25 mt-2 btn border-transparent loadBtnColor loadBtnBg justify-content-center font-weight-bold br10"
                // onClick={() => nozelmodule()}
              >
                Next
              </button>
            </form>
            <div className="paymentmethodadd">
              <br />
              <input
                type="text"
                className="inputadd me-2 form-control border-0 small theme-bg-color"
                id="otheroptions"
                aria-describedby="emailHelp"
                name=""
                placeholder="Other Option"
              />
              <button
                onClick={() => addoption()}
                class=" addbutton btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10"
              >
                Add
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <EditPay
        editpaymentshow={editpaymentshow}
        EditpaymenthandleClose={EditpaymenthandleClose}
      />

      <NozelData nozelshow={nozelshow} nozelhandleClose={nozelhandleClose} />

      <SyncProducts
        setProductshow={setProductshow}
        productshow={productshow}
        synchandleClose={synchandleClose}
      />

      <EditProduts
        Editproductshow={Editproductshow}
        EditsynchandleClose={EditsynchandleClose}
      />

      <EditBankDetails
        Editbankshow={Editbankshow}
        EditbankhandleClose={EditbankhandleClose}
      />

      <DailySheet sheetshow={sheetshow} sheethandleClose={sheethandleClose} />

      <ADS ADSshow={ADSshow} ADShandleClose={ADShandleClose} />

      <Expenses
        Expenseshow={Expenseshow}
        expensehandleClose={expensehandleClose}
      />

      <BankDetails bankshow={bankshow} bankhandleClose={bankhandleClose} />
    </>
  );
}

export default Pump;
