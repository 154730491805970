import React, { useState } from "react";
import Detail from "./details";
import Edit from "./edit";
import rightArrow from "../../images/right-arrow.svg";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import edit from "../../images/edit.svg";

// import EditModal from "./edit";
import moreIcon from "../../images/moreIcon.svg";
import { useSelector } from "react-redux";
import api from "../../apis";
import swal from "sweetalert";

export default function ProductRow({ item, fetchData, setLoader }) {
  const [editShow, setEditShow] = useState(false);
  const [detailShow, setDetailShow] = useState(false);
  const user = useSelector((state) => state.authedUser.authedUser);
  const deleteUser = async (item) => {
    try {
      if (user.type == "admin" || (user.type == "pump" && user.canUseAll)) {
        setLoader(true);
        let data = item;

        // const response = await delete_products(data);

        const response = await api.deleteproducts(data);
        console.log(data);

        if (!response) {
          throw new Error("Error while deleting");
        }

        await fetchData();

        swal({
          title: "DELETED SUCCESSFULLY !",
          icon: "success",
        });

        // window.location.reload();

        setLoader(false);
      } else {
        swal({
          title: "You are blocked due to some reason contact admin",

          icon: "warning",
        });
      }
    } catch (e) {
      setLoader(false);
    }
  };

  return (
    <tr>
      <td className="d-flex align-items-center">
        <span className="ms-3 mt-2">{item.name}</span>
      </td>
      <td>{item.unit}</td>
      <td>{item?.pump?.email}</td>
      <td>{item?.sellingrate}</td>

      <td>
        <img onClick={() => setEditShow(true)} src={edit} alt="edit" />
      </td>
      <td>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button {...bindTrigger(popupState)}>
                <img src={moreIcon} alt="" />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem
                  onClick={() => {
                    popupState.close();
                    setDetailShow(true);
                  }}
                >
                  Details
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    deleteUser(item);
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </td>
      {detailShow ? (
        <Detail show={detailShow} setShow={setDetailShow} detaildata={item} />
      ) : (
        <></>
      )}
      {editShow ? (
        <Edit
          show={editShow}
          defaultData={item}
          setShow={setEditShow}
          setLoader={setLoader}
          fetchData={fetchData}
          detaildata={item}
        />
      ) : (
        <></>
      )}
    </tr>
  );
}
