import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import rightArrow from "../../images/right-arrow.svg";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import edit from "../../images/edit.svg";
import moreIcon from "../../images/moreIcon.svg";

import api from "../../apis/index";
import { v4 as uuidv4 } from "uuid";
import Loader from "../loader";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import CompanyRow from "./companyrow";
import CompanyDetailModal from "./companyDetailModal";

import searchIcon from "../../images/search.svg";

function Pump() {
  const authedUser = useSelector((state) => state.authedUser);

  const user = authedUser.authedUser;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [loader, setLoader] = useState(false);
  const handleShow = () => setShow(true);
  const [pumps, setPumps] = useState([]);
  const [filterEmail, setFilterEmail] = useState("");
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [data, setData] = React.useState({
    name: "",
    address: "",
    email: "",
    pump: "",
    phone: "",
    days: "",
    customId: "",
    paymentType: "Credit",
    limit: "",
    billingCycle: "Weekly",
  });

  const validator = () => {
    if (!data.name) {
      return "Name is required";
    }
    if (!data.email) {
      return "Email is required";
    }
    if (!data.address) {
      return "Address is required";
    }
    if (!data.phone) {
      return "Phone is required";
    }
    if (data.phone && isNaN(data.phone)) {
      return "Phone is not correctly formatted";
    }
    let ph = data.phone.toString();

    if (ph[0] !== "0" || ph[1] !== "3") {
      return "Phone number must start from 03";
    }

    if (data.phone.length !== 11) {
      return "Phone is not correctly formatted";
    }
    if (!data.pump) {
      return "Pump is required";
    }
    if (!data.customId) {
      return "Account ID is required";
    }
    if (!data.paymentType) {
      return "Payment Type is required";
    }
    if (!data.billingCycle) {
      return "Billing Cycle is required";
    }
    if (!data.limit) {
      return "Limit is required";
    }
    if (!data.consumed) {
      return "Consumed Limit is required";
    }
    console.log("limit", parseInt(data.consumed) <= parseInt(data.limit));
    if (parseInt(data.consumed) > parseInt(data.limit)) {
      return "Consumed Limit must less than";
    }
    if (data.limit && isNaN(data.limit)) {
      return "Limit is not correctly formatted";
    }

    if (!data.days) {
      return "No of Days to block account is required";
    }
    if (data.days && isNaN(data.days)) {
      return "Days not in correct format";
    }
    return false;
  };

  useEffect(() => {
    if (user.type === "pump") {
      setData({
        ...data,
        pump: user?._id,
      });
    }
  }, [user]);

  useEffect(() => {
    fetchPumps();
  }, []);

  const handlePumpChange = (event) => {
    setData({ ...data, pump: event.target.value });
  };

  const handleNameChange = (event) => {
    setData({ ...data, name: event.target.value });
  };

  const handleAddressChange = (event) => {
    setData({ ...data, address: event.target.value });
  };

  const handleEmailChange = (event) => {
    setData({ ...data, email: event.target.value });
  };

  const handlePaymentTypeChange = (event) => {
    setData({ ...data, paymentType: event.target.value });
  };

  const handleBillingCycleChange = (event) => {
    setData({ ...data, billingCycle: event.target.value });
  };

  const handleLimitChange = (event) => {
    setData({ ...data, limit: event.target.value });
  };
  const handleConsumeLimitChange = (event) => {
    setData({ ...data, consumed: event.target.value });
  };

  const handleDaysChange = (event) => {
    setData({ ...data, days: event.target.value });
  };

  const handlePhoneChange = (event) => {
    setData({ ...data, phone: event.target.value });
  };

  const handleCustomIDChange = (event) => {
    setData({ ...data, customId: event.target.value });
  };

  const addfucn = async () => {
    try {
      if (user.type == "admin" || (user.type == "pump" && user.canUseAll)) {
        setLoader(true);

        if (validator()) {
          throw new Error(validator());
        } else {
          if (localStorage.getItem("token")) {
            console.log("User type", user.type);
            console.log("User data", user.data);

            data.limit = parseFloat(data.limit);

            function generateot() {
              var randomstring = "";
              var characters =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
              for (var i = 0; i < 40; i++) {
                randomstring += characters.charAt(
                  Math.floor(Math.random() * characters.length)
                );
              }
              return randomstring;
            }
            var onetimepass = generateot();

            const payload = {
              company_custom_id: data.customId,
              phone: data.phone,
              type: "company",
              email: data.email,
              name: data.name,
              address: data.address,
              pump: data.pump,
              limit: data.limit,
              billing_type: data.paymentType,
              billing_cycle: data.billingCycle,
              temp_limit: 0,
              lockDays: data.days,
              totalLimit: data.limit,
              otpass: onetimepass,
              consumed: data.consumed,
              companystaff: true,
            };
            // let response = await register_companies(data);
            console.log(payload);
            let response = await api.registercompany(payload);
            console.log(response);

            if (!response.success) {
              throw new Error(response.message);
            }

            setData({});
            // fetchData();
            //alert("Registeration Successfull.");
            swal({
              title: "ADDED SUCCESSULLY !",
              // text: "You clicked the button!",
              icon: "success",
            });
          }
        }
        fetchallcompanies();
        setShow(false);
        setLoader(false);
      } else {
        swal({
          title: "You are blocked due to some reason contact admin",

          icon: "warning",
        });
      }
    } catch (e) {
      console.log(e);
      // alert(e);
      swal({
        title: e,
        icon: "warning",
      });
      setLoader(false);
    }
  };

  const fetchPumps = async () => {
    try {
      setLoader(true);
      // const response = await get_pumps({ type: "pump" });
      const response = await api.getpumpsforcompany({ type: "pump" });

      console.log(response.message);
      setPumps(response.message);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const [companies, setCompanies] = useState([]);

  const updateCompany = (c) => {
    setCompanies(
      companies.map((item) => {
        if (item._id === c._id) {
          return c;
        }
        return item;
      })
    );
  };

  useEffect(() => {
    fetchallcompanies();
  }, []);

  const fetchallcompanies = async (filter = "") => {
    try {
      if (user.type == "admin") {
        const response = await api.getcompanies({
          type: "company",
          email: { $regex: filter, $options: "i" },
        });
        setCompanies(response.message);
        console.log(response);
      }
      if (user.type == "pump") {
        const response = await api.getcompanies({
          type: "company",
          pump: user._id,
          email: { $regex: filter, $options: "i" },
        });
        setCompanies(response.message);
        console.log(response);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const itemsperpages = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(companies.length / itemsperpages);

  const itemsInCurrentPage = companies.slice(
    (currentPage - 1) * itemsperpages,
    currentPage * itemsperpages
  );
  const filteredCompanies = async () => {
    let filter = document.getElementById("company-filter-email").value
      ? document.getElementById("company-filter-email").value
      : "";
    fetchallcompanies(filter);
  };

  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
        {/* Content Row */}
        <div className="row">
          {/* Area Chart */}
          <div className="col ">
            <div className=" card sortby-box br-10 border-transparent">
              {/* Card Header - Dropdown */}
              <div className="card-header br-10  bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
                <form className="d-none d-sm-inline-block form-inline me-auto ms-md-3 my-2 my-md-0 mw-100 navbar-search">
                  <h6>Filters</h6>
                  <div className="input-group">
                    <input
                      type="text"
                      id="company-filter-email"
                      className="form-control theme-bg-color border-0 small"
                      placeholder="Enter Email"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                    />
                    <div className="input-group-append">
                      <button
                        className="btn search-btn pr-4"
                        onClick={filteredCompanies}
                        type="button"
                      >
                        <img
                          src={searchIcon}
                          className="w-15 mb-1"
                          alt="serach icon"
                        />
                      </button>
                    </div>
                  </div>
                </form>
                <a
                  className=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 fs-12"
                  onClick={() => handleShow()}
                >
                  <span className="fs-14">+</span> Add New Company
                </a>
              </div>
            </div>
            {/* table */}
            {/* <Table pumps={pumps} /> */}

            <div className="card sortby-box br-10 border-transparent mt-3">
              <div className="card-header br-10 table-responsive bg-white border-transparent py-3 d-flex flex-column align-items-center justify-content-between">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th className=" pl-4">Email</th>
                      <th className="">Title</th>
                      <th className="">Address</th>
                      <th className="">Phone</th>
                      <th className="">Status</th>
                      <th className="">Consumed Limit</th>
                      <th className="">Total Limit</th>
                      <th className="">Temp limit</th>
                      <th className="">Temp limit Consumed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {typeof itemsInCurrentPage !== "string" &&
                      itemsInCurrentPage
                        .filter((item) => item.email.includes(filterEmail))
                        .map((item) => (
                          <CompanyRow
                            pumps={pumps}
                            item={item}
                            key={uuidv4()}
                            fetchallcompanies={fetchallcompanies}
                            setLoader={setLoader}
                            updateCompany={updateCompany}
                          />
                        ))}
                  </tbody>
                </table>
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        Prev
                      </button>
                    </li>
                    {[...Array(totalPages)].map((_, index) => (
                      <li
                        key={index + 1}
                        className={`page-item ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`page-item ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>

              {/* <EditModal open={editModal} setOpen={closeModal} /> */}
            </div>
          </div>

          {/* post load / bid column*/}
          {/* <LoadSecondRow /> */}
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered={true}>
        <Modal.Body>
          <div className="modalForm">
            <div className="closeIco" onClick={handleClose}>
              <i className="bi bi-x"></i>
            </div>
            <h3 className="">Add New Company</h3>
            <div className="form-group">
              <label>Account ID *</label>
              <input
                type="number"
                className="form-control theme-bg-color border-0 small"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Account ID"
                onChange={handleCustomIDChange}
              />
            </div>
            <div className="form-group">
              <label>Name *</label>
              <input
                type="text"
                className="form-control theme-bg-color border-0 small"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Name"
                onChange={handleNameChange}
              />
            </div>
            <div className="form-group">
              <label>Email address *</label>
              <input
                type="email"
                className="form-control theme-bg-color border-0 small"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Email"
                onChange={handleEmailChange}
              />
              <small id="emailHelp" className="form-text text-muted">
                We'll never share your email with anyone else.
              </small>
            </div>
            <div className="form-group">
              <label>Address *</label>
              <input
                type="text"
                className="form-control theme-bg-color border-0 small"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Adress"
                onChange={handleAddressChange}
              />
            </div>
            <div className="form-group">
              <label>Phone *</label>
              <input
                type="number"
                className="form-control theme-bg-color border-0 small"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Phone"
                onChange={handlePhoneChange}
              />
            </div>
            {user.type == "admin" && (
              <div className="form-group">
                <label>pump *</label>
                <select
                  className="form-control theme-bg-color border-0 "
                  name=""
                  id=""
                  onChange={handlePumpChange}
                >
                  <option value="" disabled>
                    Select pump
                  </option>
                  {typeof pumps !== "string" &&
                    pumps.map((item) => (
                      <option value={item._id}>
                        {item.name}({item.email})
                      </option>
                    ))}
                </select>
              </div>
            )}

            <div className="form-group">
              <label>Payment Type *</label>
              <select
                className="form-control theme-bg-color border-0 "
                name=""
                id=""
                onChange={handlePaymentTypeChange}
              >
                <option value="" disabled>
                  Select Payment Type
                </option>
                <option value="Credit">Credit</option>
                <option value="Advance">Advance</option>
              </select>
            </div>
            <div className="form-group">
              <label>Billing Cycle *</label>
              <select
                className="form-control theme-bg-color border-0 "
                name=""
                id=""
                onChange={handleBillingCycleChange}
              >
                <option value="" disabled>
                  Select Billing Cycle
                </option>
                <option value="Weekly">Weekly</option>
                <option value="Bi-Weekly">Bi Weekly</option>
                <option value="Monthly">Monthly</option>
              </select>
            </div>
            <div className="form-group">
              <label>Set Limit *</label>
              <input
                type="number"
                className="form-control theme-bg-color border-0 small"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Limit"
                onChange={handleLimitChange}
              />
            </div>
            <div className="form-group">
              <label>Consumed Limit *</label>
              <input
                type="number"
                className="form-control theme-bg-color border-0 small"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Limit"
                onChange={handleConsumeLimitChange}
              />
            </div>
            <div className="form-group">
              <label>Grace Time (in days) *</label>
              <input
                type="number"
                className="form-control theme-bg-color border-0 small"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Grace time"
                onChange={handleDaysChange}
              />
            </div>

            <div className="text-center subBtn">
              <button
                class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10"
                onClick={addfucn}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* /.container-fluid */}
    </>
  );
}

export default Pump;
