import React from "react";

import searchIcon from "../../../images/search.svg";
import profile from "../../../images/profile.jpg";
import dropdownIcon from "../../../images/dropdown-icon.svg";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../redux/actions/authedUser";
import { useSelector } from "react-redux";

function Navbar() {
  const authedUser = useSelector((s) => s.authedUser);
  const user = authedUser.authedUser;

  const dispatch = useDispatch();
  const logout = () => {
    dispatch(logoutUser());
  };
  return (
    <>
      {/* Topbar */}
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top">
        {/* Sidebar Toggle (Topbar) */}
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle me-3"
        >
          <i className="fa fa-bars" />
        </button>
        {/* Topbar Search */}
        {/* <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
          <div className="input-group">
            <input type="text" className="form-control theme-bg-color border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
            <div className="input-group-append">
              <button className="btn search-btn pr-4" type="button">
                <img src={searchIcon} className="w-15 mb-1" alt="serach icon" />
              </button>
            </div>
          </div>
        </form> */}
        {/* Topbar Navbar */}
        <ul className="navbar-nav ms-auto">
          {/* Nav Item - User Information */}
          <li className="nav-item dropdown no-arrow">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img className="img-profile rounded-circle" src={profile} />

              <div className="profile-div ms-2">
                <span className="me-2 small profile-name">{user.name}</span>
                <span className="status">{user.email}</span>
              </div>
              <div>
                <img
                  src={dropdownIcon}
                  className="w-9 ms-1 mb-2"
                  alt="dropdown icon"
                />
              </div>
            </a>
            {/* Dropdown - User Information */}
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="dropdownMenuButton1"
            >
              <a
                onClick={logout}
                className="dropdown-item"
                href="#"
                data-toggle="modal"
                data-target="#logoutModal"
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw me-2 text-gray-400" />
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
      {/* End of Topbar */}
    </>
  );
}
export default Navbar;
