import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

import { v4 as uuidv4 } from "uuid";
import Loader from "../loader";
import swal from "sweetalert";
import searchIcon from "../../images/search.svg";

import { useSelector } from "react-redux";

import api from "../../apis/index";
import ProductRow from "./productRow";

function Pump() {
  const [productdata, setproductData] = useState([]);
  const authedUser = useSelector((state) => state.authedUser);

  const user = authedUser.authedUser;

  const [show, setShow] = useState(false);

  const [selectedValue, setSelectedValue] = React.useState("a");

  const [loader, setLoader] = useState(false);

  const [pumps, setPumps] = useState([]);
  // const [open, setOpen] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [data, setData] = React.useState({
    name: "",
    unit: "ltr",
    pump: "",
    sellingrate: "",
  });

  useEffect(() => {
    if (user.type === "pump") {
      setData({
        ...data,
        pump: user?._id,
      });
    } else {
      fetchPumps();
    }
    fetchproducts();
  }, []);

  const fetchproducts = async (filter = "") => {
    try {
      setLoader(true);
      let permission = {
        filter: { $regex: filter, $options: "i" },
      };
      if (user.type === "pump") {
        permission = {
          ...permission,
          pump: user._id,
        };
      }
      console.log(permission);
      const response = await api.readproducts(permission);
      console.log(response.message);
      setproductData(response.message);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const filteredProducts = async () => {
    let filter = document.getElementById("product-name-filter").value
      ? document.getElementById("product-name-filter").value
      : "";
    fetchproducts(filter);
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const validator = () => {
    if (!data.name) {
      return "Name is required";
    }
    if (!data.unit) {
      return "Unit is required";
    }
    if (!data.pump) {
      return "Pump is required";
    }
    if (!data.sellingrate) {
      return "Selling rate is required";
    }
    return false;
  };
  const handlePumpChange = (event) => {
    setData({ ...data, pump: event.target.value });
  };

  const handleNameChange = (event) => {
    setData({ ...data, name: event.target.value });
  };

  const handleUnitChange = (event) => {
    console.log("--");
    setData({ ...data, unit: event.target.value });
  };

  const handlesellingChange = (event) => {
    setData({ ...data, sellingrate: parseFloat(event.target.value) });
  };

  const fetchPumps = async () => {
    try {
      setLoader(true);
      // const response = await get_pumps({ type: "pump" });
      const response = await api.getpumpsforcompany({ type: "pump" });
      setPumps(response.message);
      if (response.message.length > 0) {
        setData({
          ...data,
          pump: response.message[0]._id,
        });
      }
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const addFunc = async () => {
    try {
      if (user.type == "admin" || (user.type == "pump" && user.canUseAll)) {
        setLoader(true);
        if (validator()) {
          console.log(data);
          throw new Error(validator());
        } else {
          if (localStorage.getItem("token")) {
            // data.currentQuantity = data.quantity;
            data.rate = parseFloat(data.sellingrate);

            const payload = {
              pump: data.pump,
              name: data.name,
              unit: data.unit,
              // image: data.image,
              sellingrate: data.sellingrate,
            };
            console.log("product", payload);

            const response = await api.createproducts(payload);

            if (!response) {
              throw new Error("Error while adding new product");
            }
            setData({
              name: "",
              unit: "ltr",
              pump: data.pump,
              sellingrate: "",
            });
            handleClose();
            swal({
              title: "PRODUCT ADDED SUCCESSFULLY !",
              icon: "success",
            });
            fetchproducts();
          }
        }
        setLoader(false);
      } else {
        swal({
          title: "You are blocked due to some reason contact admin",
          icon: "warning",
        });
      }
    } catch (e) {
      console.log(e);
      swal({
        title: e,
        icon: "warning",
      });
      setLoader(false);
    }
  };

  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
        {/* Content Row */}
        <div className="row">
          {/* Area Chart */}
          <div className="col ">
            <div className=" card sortby-box br-10 border-transparent">
              {/* Card Header - Dropdown */}
              <div className="card-header br-10  bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
                <form className="d-none d-sm-inline-block form-inline me-auto ms-md-3 my-2 my-md-0 mw-100 navbar-search">
                  <h6>Filters</h6>
                  <div className="input-group">
                    <input
                      type="text"
                      id="product-name-filter"
                      className="form-control theme-bg-color border-0 small"
                      placeholder="Enter Name"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                    />
                    <div className="input-group-append">
                      <button
                        className="btn search-btn pr-4"
                        onClick={filteredProducts}
                        type="button"
                      >
                        <img
                          src={searchIcon}
                          className="w-15 mb-1"
                          alt="serach icon"
                        />
                      </button>
                    </div>
                  </div>
                </form>
                <a
                  className=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 fs-12"
                  onClick={() => handleShow()}
                >
                  <span className="fs-14">+</span> Add New Product
                </a>
              </div>
            </div>
            {/* table */}
            <Table productdata={productdata} fetchproducts={fetchproducts} />
          </div>

          {/* post load / bid column*/}
          {/* <LoadSecondRow /> */}
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered={true}>
        <Modal.Body>
          <div className="modalForm">
            <div className="closeIco" onClick={handleClose}>
              <i className="bi bi-x"></i>
            </div>
            <h3 className="">Add New Product</h3>
            <div className="form-group">
              <label>Name *</label>
              <input
                type="text"
                className="form-control theme-bg-color border-0 small"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Name"
                onChange={handleNameChange}
              />
            </div>

            <div className="form-group">
              <label>Selling Rate *</label>
              <input
                type="text"
                className="form-control theme-bg-color border-0 small"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Selling Rate"
                onChange={handlesellingChange}
              />
            </div>
            {user.type == "admin" && (
              <div className="form-group">
                <label>pump *</label>
                <select
                  className="form-control theme-bg-color border-0 "
                  name=""
                  id=""
                  onChange={handlePumpChange}
                >
                  <option value="" disabled>
                    Select pump
                  </option>

                  {typeof pumps !== "string" &&
                    pumps.map((item) => (
                      <option value={item._id}>
                        {item.name}({item.email})
                      </option>
                    ))}
                </select>
              </div>
            )}

            <div className="form-group">
              <label>select unit *</label>
              <select
                className="form-control theme-bg-color border-0 "
                name=""
                id=""
                onChange={handleUnitChange}
                defaultValue="ltr"
              >
                <option value="select">Select Unit</option>
                <option value="ltr">Litre</option>
                <option value="kg">Per Box</option>
              </select>
            </div>
            <div className="text-center subBtn">
              <button
                class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10"
                onClick={addFunc}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* /.container-fluid */}
      {loader && <Loader />}
    </>
  );
}

const Table = ({ productdata, fetchproducts }) => {
  const authedUser = useSelector((state) => state.authedUser);

  const user = authedUser.authedUser;

  const [data, setData] = React.useState({
    name: "",
    unit: "",
    pump: "",
    sellingrate: "",
  });

  const [loader, setLoader] = useState(false);

  const itemsperpages = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(productdata.length / itemsperpages);

  const itemsInCurrentPage = productdata.slice(
    (currentPage - 1) * itemsperpages,
    currentPage * itemsperpages
  );

  return (
    <div className="card sortby-box br-10 border-transparent mt-3">
      <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 d-flex flex-column align-items-center justify-content-between">
        <table class="table table-hover">
          <thead>
            <tr>
              <th className=" pl-4">Name</th>
              <th className="">Unit</th>
              <th className="">Pump</th>
              <th className="">Selling Rate</th>
            </tr>
          </thead>
          <tbody>
            {typeof itemsInCurrentPage !== "string" &&
              itemsInCurrentPage.map((item) => (
                <ProductRow
                  setLoader={setLoader}
                  key={uuidv4()}
                  item={item}
                  fetchData={fetchproducts}
                />
              ))}
          </tbody>
        </table>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                Prev
              </button>
            </li>
            {[...Array(totalPages)].map((_, index) => (
              <li
                key={index + 1}
                className={`page-item ${
                  currentPage === index + 1 ? "active" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => setCurrentPage(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>

      {/* <EditModal open={editModal} setOpen={closeModal} /> */}

      {loader && <Loader />}
    </div>
  );
};

export default Pump;
