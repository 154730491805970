import React, { useEffect, useState } from "react";
import {
  Outlet,
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import "./App.css";
import "./component/global/global.css";
import "./component/dashboard/dashboard.css";
import "./component/pump/loads.css";
import "./component/company/company.css";

import $ from "jquery";
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
// import '../node_modules/jquery/dist/jquery.min.js';
// import '../node_modules/jquery.easing/jquery.easing';

// import '../node_modules/popper.js/dist/popper'

// route
import { AdminPages } from "./component/admin/admin";
import { Login } from "./component/global/Login/login";

import { LoginCompany } from "./component/global/LoginCompany/login";
import { LoginAdmin } from "./component/global/LoginAdmin/login";
import { LoginPump } from "./component/global/LoginPump/login";
import { LoginStaff } from "./component/global/LoginStaff/login";
import { LoginVehicle } from "./component/global/LoginVehicle/login";

import { Forgotpassword } from "./component/global/Forgotpassword/forgotPass";
import { CreateAccount } from "./component/global/createAccount/createAccount";
import { HrVerification } from "./component/global/hrVerification/hrVerification";
import { get_user, loginUser } from "./redux/actions/authedUser";
import { SetPassword } from "./component/global/setPassword";

const SecureRoute = () => {
  const authedUser = useSelector((state) => state.authedUser);
  if (Object.keys(authedUser.authedUser).length > 0) {
    return <AdminPages />;
  } else {
    console.log("here");
    return <Navigate to="/login" />;
  }
};

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const authedUser = useSelector((state) => state.authedUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      get_user(
        localStorage.getItem("email"),
        localStorage.getItem("type"),
        localStorage.getItem("token")
      )
        .then((r) => {
          console.log(r);
          if (r.ok) {
            return r.json();
          } else {
            localStorage.removeItem("token");
            localStorage.removeItem("email");
            localStorage.removeItem("type");
            return { success: false };
          }
        })
        .then((res) => {
          if (res.success) {
            res.message[0].token = localStorage.getItem("token");
            dispatch(loginUser(res.message[0]));
          }
        });
    }
  }, []);

  return (
    <>
      <Routes>
        <Route
          exact
          path="/login"
          element={<Login setIsLogin={setIsLogin} />}
        />
        <Route
          exact
          path="/loginadmin"
          element={<LoginAdmin setIsLogin={setIsLogin} />}
        />
        <Route
          exact
          path="/logincompany"
          element={<LoginCompany setIsLogin={setIsLogin} />}
        />
        <Route
          exact
          path="/loginpump"
          element={<LoginPump setIsLogin={setIsLogin} />}
        />
        <Route
          exact
          path="/loginstaff"
          element={<LoginStaff setIsLogin={setIsLogin} />}
        />
        <Route
          exact
          path="/loginvehicle"
          element={<LoginVehicle setIsLogin={setIsLogin} />}
        />
        <Route exact path="/account" element={<CreateAccount />}></Route>
        <Route
          exact
          path="/ForgotPassword"
          element={<Forgotpassword />}
        ></Route>
        <Route exact path="/hr" element={<HrVerification />} />
        <Route exact path="/set-password" element={<SetPassword />} />
        <Route exact path="/" element={<Navigate to="/login" />} />
        <Route path="/*" element={<SecureRoute />} />
      </Routes>
    </>
  );
}

export { App };
