import React from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import api from "../../apis";
import helpers from "../../helpers/helpers";
import swal from "sweetalert";

export default function CompanyEditModal({
  pumps,
  editshow,
  edithandleClose,
  currentData,
  setLoader,
  fetchallcompanies,
}) {
  console.log("currentData", currentData);

  const authedUser = useSelector((state) => state.authedUser);

  const user = authedUser.authedUser;

  const saveDetails = async () => {
    console.log("saveDetails");
    if (user.type == "admin" || (user.type == "pump" && user.canUseAll)) {
      try {
        setLoader(true);
        let customId = document.getElementById("edit-company-custom-id").value;
        let name = document.getElementById("name").value;
        let email = document.getElementById("email").value;
        let address = document.getElementById("address").value;
        let phone = document.getElementById("phone").value;
        // let pump = document.getElementById("edit-company-custom-id").value;
        let paymentType = document.getElementById("paymenttype").value;
        let billingCycle = document.getElementById("billing").value;
        let totalLimit = document.getElementById("totallimit").value;
        let grace = document.getElementById("edit-company-grace").value;

        let data = {
          name,
          email,
          address,
          phone,
          // pump,
          days: grace,
          customId,
          paymentType,
          totalLimit,
          billingCycle,
        };
        let err = validator(data);
        if (err) {
          throw new Error(validator(data));
        } else {
          if (localStorage.getItem("token")) {
            if (
              parseFloat(data.totalLimit) <= parseFloat(currentData.consumed)
            ) {
              throw new Error("Limit is less than consumed limit");
            }
            const diff =
              parseFloat(currentData.totalLimit) -
              parseFloat(currentData.limit);
            data.limit =
              parseFloat(data.totalLimit) - parseFloat(Math.abs(diff));
            if (!currentData.isApproved) {
              data.limit = parseFloat(data.totalLimit);
            }
            data.limit = parseFloat(data.limit);
            data.totalLimit = parseFloat(data.totalLimit);
            console.log("data", data);
            let response = await api.update_company({
              user: { _id: currentData._id },
              update: data,
            });

            if (response.success) {
              setLoader(false);
              swal({
                title: "Company updated successfully",
                icon: "success",
              });
              edithandleClose();
              fetchallcompanies();
            } else {
              setLoader(false);
              swal({
                title: response.message,
                icon: "warning",
              });
            }
          }
        }
      } catch (e) {
        console.log(e);
        swal({
          title: e,
          icon: "warning",
        });
        setLoader(false);
      }
    } else {
      swal({
        title: "You are blocked due to some reason contact admin",
        icon: "warning",
      });
    }
  };

  const validator = (data) => {
    if (!data.name) {
      return "Name is required";
    }
    if (!data.email) {
      return "Email is required";
    }
    if (data.email && !helpers.isValidEmail(data.email)) {
      return "Email is not correctly formatted";
    }
    if (!data.address) {
      return "Address is required";
    }
    if (!data.phone) {
      return "Phone is required";
    }
    let ph = data.phone.toString();

    if (ph[0] !== "0" || ph[1] !== "3") {
      return "Phone number must start from 03";
    }

    if (data.phone.length !== 11) {
      return "Phone is not correctly formatted";
    }
    if (data.phone && isNaN(data.phone)) {
      return "Phone is not correctly formatted";
    }
    // if (!data.pump) {
    //   return "Pump is required";
    // }
    if (!data.days) {
      return "No of Days to block account is required";
    }
    if (data.days && isNaN(data.days)) {
      return "Days not in correct format";
    }
    if (!data.customId) {
      return "Account ID is required";
    }
    if (!data.paymentType) {
      return "Payment Type is required";
    }
    if (!data.totalLimit) {
      return "Limit is required";
    }
    if (data.totalLimit && isNaN(data.totalLimit)) {
      return "Limit is not correctly formatted";
    }
    if (!data.billingCycle) {
      return "Billing Cycle is required";
    }
    console.log(data);
    return false;
  };

  return (
    <Modal show={editshow} onHide={edithandleClose} centered={true}>
      <Modal.Body>
        <div className="modalForm">
          <div className="closeIco" onClick={edithandleClose}>
            <i className="bi bi-x"></i>
          </div>
          <h3 className="">Edit Company</h3>
          <div className="form-group">
            <label htmlFor="edit-company-custom-id">Account ID *</label>
            <input
              defaultValue={currentData?.company_custom_id}
              type="number"
              className="form-control theme-bg-color border-0 small"
              id="edit-company-custom-id"
              aria-describedby="emailHelp"
              placeholder="Enter Account ID"
            />
          </div>
          <div className="form-group">
            <label htmlFor="edit-company-name">Name *</label>
            <input
              defaultValue={currentData?.name}
              type="text"
              className="form-control theme-bg-color border-0 small"
              id="name"
              aria-describedby="emailHelp"
              placeholder="Enter Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="edit-company-email">Email address *</label>
            <input
              defaultValue={currentData?.email}
              type="email"
              className="form-control theme-bg-color border-0 small"
              id="email"
              aria-describedby="emailHelp"
              placeholder="Enter Email"
            />
            <small id="emailHelp" className="form-text text-muted">
              We'll never share your email with anyone else.
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="edit-company-address">Address *</label>
            <input
              defaultValue={currentData?.address}
              type="text"
              className="form-control theme-bg-color border-0 small"
              id="address"
              aria-describedby="emailHelp"
              placeholder="Enter Adress"
            />
          </div>
          <div className="form-group">
            <label htmlFor="edit-company-phone">Phone *</label>
            <input
              defaultValue={currentData?.phone}
              type="number"
              className="form-control theme-bg-color border-0 small"
              id="phone"
              aria-describedby="emailHelp"
              placeholder="Enter Phone"
            />
          </div>
          {/* <div className="form-group">
            <label htmlFor="edit-company-pump">pump *</label>

            <select className="form-control theme-bg-color border-0 " name="" id="edit-company-pump">
              {pumps.map(p => {
                return <option selected={p._id === currentData?.pump?._id} value={`edit-pump-${p._id}`}>{p.name}</option>
              })}
            </select>
          </div> */}
          <div className="form-group">
            <label htmlFor="edit-company-payment-type">Payment Type *</label>
            <select
              className="form-control theme-bg-color border-0 "
              defaultValue={currentData?.billing_type}
              name="edit-company-payment-type"
              id="paymenttype"
            >
              <option
                value=""
                selected={
                  currentData?.billing_type !== "Credit" ||
                  currentData?.billing_type !== "Advanced"
                }
                disabled
              >
                Select payment type
              </option>
              <option
                value="Advance"
                selected={currentData?.billing_type === "Advance"}
              >
                Advanced
              </option>
              <option
                value="Credit"
                selected={currentData?.billing_type === "Credit"}
              >
                Credit
              </option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="edit-company-billing-cycle">Billing Cycle *</label>
            <select
              className="form-control theme-bg-color border-0 "
              name="edit-company-billing-cycle"
              id="billing"
            >
              <option
                value=""
                selected={currentData?.billing_cycle ? true : false}
                disabled
              >
                Select cycle
              </option>
              <option
                value="Monthly"
                selected={currentData?.billing_cycle === "Monthly"}
              >
                Monthly
              </option>
              <option
                value="Bi-Weekly"
                selected={currentData?.billing_cycle === "Bi-Weekly"}
              >
                Bi-Weekly
              </option>
              <option
                value="Weekly"
                selected={currentData?.billing_cycle === "Weekly"}
              >
                Weekly
              </option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="edit-company-limit">Set Limit *</label>
            <input
              defaultValue={currentData?.limit}
              type="number"
              className="form-control theme-bg-color border-0 small"
              id="totallimit"
              aria-describedby="emailHelp"
              placeholder="Enter Limit"
            />
          </div>
          <div className="form-group">
            <label htmlFor="edit-company-grace">Grace Time (in days) *</label>
            <input
              defaultValue={currentData?.lockDays}
              type="number"
              className="form-control theme-bg-color border-0 small"
              id="edit-company-grace"
              aria-describedby="emailHelp"
              placeholder="gracetime"
            />
          </div>

          <div className="text-center subBtn">
            <button
              onClick={saveDetails}
              class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10"
            >
              Submit
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
