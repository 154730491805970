import React, { useState } from "react";
import SiteNav from "../siteNavbar/siteNavbar";
import modalImg from "../../../images/modalImg.png";
import { useNavigate, Navigate, NavLink } from "react-router-dom";
// import { withRouter, Redirect, Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { Footer } from "../footer/footer";
import { cardHeaderClasses } from "@mui/material";
import api from "../../../apis";
import { loginUserReq } from "../../../redux/actions/authedUser";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export function Login({ setIsLogin }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState("pump");

  const authedUser = useSelector((state) => state.authedUser);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    document.getElementById(`login-email`).value = "";
    document.getElementById(`login-pwd`).value = "";
  };

  const dologin = async () => {
    try {
      const data = {
        email: document.getElementById(`login-email`).value,
        password: document.getElementById(`login-pwd`).value,
        type: value,
      };
      console.log("login", data);

      dispatch(loginUserReq(data.email, data.password, data.type)).then(
        (r) => {}
      );
    } catch (e) {
      console.log(e);
      alert(e);
    }
  };

  if (Object.keys(authedUser.authedUser).length === 0) {
    return (
      <div className="loginPage">
        <SiteNav />
        <div className="contactModal">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-7">
                <div className="modalImg">
                  <img className="img-fluid" src={modalImg} alt="pump" />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="mainContent">
                  <div className="heading text-center p-0">
                    <h1>Login</h1>
                    <p className="mb-4">Find out more about pumpify.</p>
                  </div>
                  <div className="row mx-0">
                    <div className="col-md-12 d-flex flex-column ">
                      {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                                <Tab label="Pump" {...a11yProps('pump')} />
                                                <Tab label="Company" {...a11yProps('company')} />
                                                <Tab label="Staff" {...a11yProps('vehicle')} />
                                                <Tab label="Vehicle" {...a11yProps('staff')} />
                                            </Tabs>
                                        </Box> */}
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="wrapped label tabs example"
                      >
                        <Tab value="pump" label="Pump" />
                        <Tab value="company" label="Company" />
                        <Tab value="vehicle" label="Vehicle" />
                        <Tab value="staff" label="Staff" />
                      </Tabs>
                      <div className="row mx-0">
                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label htmlFor="login-email">Email *</label>
                            <input
                              name="login-email"
                              type="text"
                              className="form-control theme-bg-color "
                              id="login-email"
                              placeholder="Your Email"
                            />
                          </div>
                          <div className="form-group mb-3">
                            <label htmlFor="login-pwd">Password *</label>
                            <input
                              name="login-pwd"
                              type="password"
                              className="form-control theme-bg-color "
                              id="login-pwd"
                              aria-describedby="emailHelp"
                              placeholder="Your Password"
                            />
                            <NavLink
                              className=" text-danger"
                              to="/ForgotPassword"
                            >
                              {" "}
                              Forgot Password?
                            </NavLink>
                          </div>
                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn sendBtn"
                              onClick={dologin}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <NavLink className="" to="/loginpump">
                                                <button className="mt-4 btn sendBtn">Login As Pump</button>
                                            </NavLink>
                                            <NavLink className="" to="/logincompany">
                                                <button className="mt-4 btn sendBtn">Login As Company</button>
                                            </NavLink>
                                            <NavLink className="" to="/loginvehicle">
                                                <button className="mt-4 btn sendBtn">Login As Vehicle</button>
                                            </NavLink>
                                            <NavLink className="" to="/loginstaff">
                                                <button className="mt-4 btn sendBtn">Login As Staff</button>
                                            </NavLink> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  } else {
    return <Navigate to="/dashboard" />;
  }
}
