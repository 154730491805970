import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import api from "../../apis";
import SyncProducts from "./syunc_products";
import BankDetails from "./bankdetails";
import edit from "../../images/delete.svg";
import "./accounting.css";
import Loader from "../loader";

export default function Nozel({ nozelshow, nozelhandleClose }) {
  const user = useSelector((state) => state.authedUser.authedUser);
  console.log(user);

  const [productshow, setProductshow] = useState(false);

  const [bankshow, setBankshow] = useState(false);

  const bankhandleClose = () => {
    setBankshow(false);
  };

  const synchandleClose = () => {
    setProductshow(false);
  };

  const productmodule = () => {
    nozelhandleClose();
    setProductshow(true);
  };

  const [formValues, setFormValues] = useState([
    { nozelId: "", startReading: "", product: "", pump: user._id },
  ]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchAllProduct();
  }, []);

  const fetchAllProduct = async () => {
    try {
      // setLoader(true);

      if (user.type == "pump") {
        const response = await api.readproducts({ pump: user?._id });

        if (response.success) {
          setProducts(response.message);
        }
      }
      // setLoader(false);
    } catch (e) {
      console.log(e);
      // setLoader(false);
    }
  };

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;

    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { nozelId: "", startReading: "", product: "", pump: user._id },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let handleSubmit = async (event) => {
    // alert(JSON.stringify(formValues));
    try {
      event.preventDefault();
      const payload = {
        pump: user?._id,
        nozels: formValues,
      };
      const response = await api.managenozels(payload);
      console.log(payload);

      if (response.success) {
        swal({
          title: response.message,
          icon: "success",
        });
        productmodule();
      }
    } catch (e) {
      console.log(e);
      swal({
        title: e,
        icon: "error",
      });
    }
  };

  return (
    <>
      <Modal show={nozelshow} onHide={nozelhandleClose} centered={true}>
        <Modal.Body>
          <div className="modalForm br-10 d-flex methodcontainer">
            <div className="closeIco" onClick={nozelhandleClose}>
              <i className="bi bi-x"></i>
            </div>
            <h3 className="">Add Nozel Data</h3>
            <button
              class=" w-25 btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 ms-auto"
              onClick={() => addFormFields()}
            >
              Add
            </button>

            <form onSubmit={handleSubmit}>
              {formValues.map((element, index) => (
                <div className="form-inline" key={index}>
                  <div className="NozelContainer theme-bg-color">
                    {index ? (
                      <img
                        onClick={() => removeFormFields(index)}
                        className="delete"
                        src={edit}
                        alt=""
                      />
                    ) : null}
                    <br />
                    <div>
                      <input
                        onChange={(e) => handleChange(index, e)}
                        type="text"
                        className="input1 form-control border-0 small"
                        id="edit-pump-name"
                        aria-describedby="emailHelp"
                        name="nozelId"
                        placeholder="Enter Nozel ID *"
                      />
                      <input
                        onChange={(e) => handleChange(index, e)}
                        type="text"
                        className="input2 form-control border-0 small"
                        id="edit-pump-name"
                        aria-describedby="emailHelp"
                        name="startReading"
                        placeholder="Enter Start Reading *"
                      />
                      <select
                        onChange={(e) => handleChange(index, e)}
                        className="form-control border-0 selectionProduct"
                        name="product"
                        id=""
                      >
                        <option value="" selected disabled>
                          {" "}
                          Select Product{" "}
                        </option>
                        {typeof products !== "string" &&
                          products.map((item) => (
                            <option value={item._id}>{item.name}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              ))}

              <button
                type="submit"
                class="w-25 mt-5 btn border-transparent loadBtnColor loadBtnBg justify-content-center font-weight-bold br10"
                // onClick={() => productmodule()}
              >
                Next
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <SyncProducts
        productshow={productshow}
        setProductshow={setProductshow}
        synchandleClose={synchandleClose}
      />

      <BankDetails bankshow={bankshow} bankhandleClose={bankhandleClose} />
    </>
  );
}
