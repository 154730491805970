import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";

import pumpifylogo from "../../../images/logo.png";
import modalImg from "../../../images/modalImg.png";

export const Footer = () => {
  const [getModalText, setGetModalText] = useState("");
  const [showGetIn, setShowGetIn] = useState(false);
  const [newLetterMail, setnewLetterMail] = useState("");
  const [demoForm, setDemoForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  // notification setting
  const notify = (response, types) =>
    types(response, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const handleCloseGet = () => setShowGetIn(false);
  const handleShowGet = (text, e) => {
    e.preventDefault();
    setShowGetIn(true);
    setGetModalText(text);
  };
  const handleDemoF = (e) => {
    setDemoForm({ ...demoForm, [e.target.name]: e.target.value });
  };

  const submitDemoForm = (e) => {
    e.preventDefault();
    console.log(demoForm);
    if (!demoForm.email) {
      return notify("email is required", toast.error);
    }
    if (!demoForm.message) {
      return notify("message is required", toast.error);
    }
    const response = axios
      .post("https://api.pumpify.co/get-demo-request-mailer", demoForm)
      .then((res) => {
        console.log(res);
        notify("Thanks, we will reach you soon.", toast.success);
        handleCloseGet(false);
        setDemoForm({
          name: "",
          email: "",
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
        notify("Something went wrong", toast.error);
        handleCloseGet(false);
      });
  };

  const submitNewsForm = (e) => {
    e.preventDefault();
    console.log(newLetterMail);
    if (!newLetterMail) {
      return notify("email is required", toast.error);
    }
    const response = axios
      .post("https://api.pumpify.co/subscribe_news_letter", {
        email: newLetterMail,
      })
      .then((res) => {
        console.log(res);
        notify("Thanks for subscribe", toast.success);
        setnewLetterMail("");
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message == "Email already subscribed") {
          notify(err?.response?.data?.message, toast.error);
        } else {
          notify("Something went wrong", toast.error);
        }
      });
  };
  return (
    <>
      {/* footer start */}
      <div className="footer mt-5">
        <div className="container-fluid bg-dark text-secondary  fMain">
          <div className="row gx-5 mx-xs-0">
            <div className="col-lg-8 col-md-6">
              <div className="row gx-5 mx-xs-0">
                <div className="col-lg-4 col-md-12 pt-5 mb-5">
                  <div className="fLogo mb-4">
                    <img src={pumpifylogo} alt="" className="pumplogo" />
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-geo-alt text-primary me-2" />
                    <p className="mb-0">
                      Ayra Heights, Gulistan-e-Johar, Block 15, Karachi.
                    </p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-envelope-open text-primary me-2" />
                    <a className="mb-0" href="mailto:contact@pumpify.co">
                      contact@pumpify.co
                    </a>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-telephone text-primary me-2" />
                    <a className="mb-0" href="tel:+92 335 2491559">
                      +92 335 2491559
                    </a>
                  </div>
                  <div className="d-flex mt-4">
                    <a
                      className="btn btn-primary btn-square rounded-circle me-2"
                      href="#"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                    <a
                      className="btn btn-primary btn-square rounded-circle me-2"
                      href="#"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a
                      className="btn btn-primary btn-square rounded-circle me-2"
                      href="#"
                    >
                      <i className="fab fa-linkedin-in" />
                    </a>
                    <a
                      className="btn btn-primary btn-square rounded-circle"
                      href="#"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <h4 className="text-uppercase text-light mb-4">
                    Quick Links
                  </h4>
                  <div className="d-flex flex-column justify-content-start">
                    <a className="text-secondry mb-2" href="">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Home
                    </a>
                    <a
                      className="text-secondry mb-2"
                      href=""
                      onClick={(e) => handleShowGet("Demo", e)}
                    >
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Demo
                    </a>
                    <a
                      className="text-secondry mb-2"
                      href="https://dashboard.pumpify.co/#/hr"
                    >
                      <i className="bi bi-arrow-right text-primary me-2" />
                      HR verification
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <h4 className="text-uppercase text-light mb-4">
                    Popular Links
                  </h4>
                  <div className="d-flex flex-column justify-content-start">
                    <a className="text-secondry mb-2" href="#">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Home
                    </a>
                    <a
                      className="text-secondry mb-2"
                      href="https://dashboard.pumpify.co/#/account"
                    >
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Sign Up
                    </a>
                    <a
                      className="text-secondry mb-2"
                      href="https://dashboard.pumpify.co/#/login"
                    >
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Login
                    </a>
                    <a className="text-secondry" href="#">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 newslMain bg-primary ">
                <h4 className="text-uppercase text-white mb-4">Newsletter</h4>
                <h6 className="text-uppercase text-white">
                  Subscribe Our Newsletter
                </h6>
                <p className="text-light">
                  Membership/customer deals and promotions
                </p>
                <form onSubmit={(e) => submitNewsForm(e)}>
                  <div className="input-group">
                    <input
                      type="email"
                      onChange={(e) => setnewLetterMail(e.target.value)}
                      value={newLetterMail}
                      className="form-control border-white p-3"
                      placeholder="Your Email *"
                    />
                    <button className="btn btn-dark">Sign Up</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid py-4 py-lg-0 px-5"
          style={{ background: "#111111" }}
        >
          <div className="row gx-5">
            <div className="col-lg-8">
              <div className="py-lg-4 text-center">
                <p className="text-secondary mb-0">
                  ©{" "}
                  <a className="text-light fw-bold" href="#">
                    pumpify
                  </a>
                  . All Rights Reserved.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="py-lg-4 text-center credit">
                <p className="text-light mb-0">
                  Develop by{" "}
                  <a
                    className="text-light fw-bold"
                    href="https://dappomatics.com/"
                  >
                    dappomatics
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer end */}
      {/* get in touch */}
      <Modal
        className="demoModal"
        show={showGetIn}
        onHide={handleCloseGet}
        centered={true}
      >
        <Modal.Body className="contactModal">
          <div className="container">
            <div className="heading text-center">
              <h1>{getModalText}</h1>
              <p className="mb-4">Find out more about pumpify.</p>
              <i class="fa fa-times" onClick={handleCloseGet}></i>
            </div>
            <div className="row ai-center">
              <div className="col-lg-7">
                <div className="modalImg">
                  <img className="img-fluid" src={modalImg} alt="pump" />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="mainContent">
                  <div className="row mx-0">
                    <div className="col-md-12">
                      <form onSubmit={(e) => submitDemoForm(e)}>
                        <div className="form-group mb-4">
                          <label htmlFor="Name">Name</label>
                          <input
                            onChange={(e) => handleDemoF(e)}
                            name="name"
                            value={demoForm.name}
                            type="text"
                            className="form-control theme-bg-color"
                            id="exampleInputPassword1"
                            placeholder="Your Name "
                          />
                        </div>
                        <div className="form-group mb-4">
                          <label htmlFor="exampleInputEmail1">
                            Email address *
                          </label>
                          <input
                            onChange={(e) => handleDemoF(e)}
                            name="email"
                            value={demoForm.email}
                            type="email"
                            className="form-control theme-bg-color"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Your email address*"
                          />
                        </div>

                        <div className="form-group mb-4">
                          <label htmlFor="exampleInputEmail1">Message *</label>
                          <textarea
                            onChange={(e) => handleDemoF(e)}
                            name="message"
                            value={demoForm.message}
                            type="text"
                            className="form-control theme-bg-color"
                            id="exampleInputPassword1"
                            placeholder="Write message here*"
                            rows={4}
                          />
                        </div>
                        <div className="text-center mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary sendBtn"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
