import React, { useState } from "react";
import { useSelector } from "react-redux";

import EditPumpModal from "./editpumpmodal";

import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import edit from "../../images/edit.svg";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import moreIcon from "../../images/moreIcon.svg";
import Detailpumpmodal from "./detailpumpmodal";
import api from "../../apis/index";
import swal from "sweetalert";

export default function PumpRow({ fetchPumps, item, setLoader, updatePump }) {
  const [editshow, seteditShow] = useState(false);
  const [detailshow, setdetailShow] = useState(false);

  const detailhandleClose = (param) => {
    setdetailShow(false);
  };
  const detailhandleShow = (param) => {
    setdetailShow(true);
  };

  const edithandleClose = () => seteditShow(false);
  const edithandleShow = () => seteditShow(true);

  const authedUser = useSelector((state) => state.authedUser);
  const user = authedUser.authedUser;

  const changestatus = async (item) => {
    try {
      if (user.type === "admin") {
        setLoader(true);
        let data = item;
        data.status = !data.status;

        const response = await api.updatepump({
          user: {
            _id: item._id,
          },
          update: item,
        });

        console.log("item", item._id);
        if (response.success) {
          swal({
            title: "STATUS UPDATED SUCCESSFULLY !",
            // text: "You clicked the button!",
            icon: "success",
          });
        }
        fetchPumps();
        setLoader(false);
      } else {
        swal({
          title: "You are blocked due to some reason contact admin",
          icon: "warning",
        });
      }
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const deleteUser = async (item) => {
    try {
      if (user.type == "admin") {
        setLoader(true);
        let data = {
          _id: item._id,
        };
        console.log(data);

        const response = await api.deletepump(data);

        if (!response) {
          throw new Error("Error while deleting");
        }
        swal({
          title: "DELETED SUCCESSFULLY !",
          // text: "You clicked the button!",
          icon: "success",
        });
        fetchPumps();
        setLoader(false);
      } else {
        swal({
          title: "You are blocked due to some reason contact admin",

          icon: "warning",
        });
      }
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const changeapproveStatus = async (item) => {
    try {
      if (user.type === "admin") {
        setLoader(true);

        let response = await api.pumpapprove({
          _id: item._id,
        });
        if (!response) {
          throw new Error("Error while updating status");
        }

        const res = await api.updatepump({
          user: {
            _id: item._id,
          },
          update: {
            canUseAll: !item.canUseAll,
          },
        });
        if (res.success) {
          fetchPumps();
          swal({
            title: "STATUS UPDATED SUCCESSFULLY !",

            icon: "success",
          });
        }
        setLoader(false);
      } else {
        swal({
          title: "You are blocked due to some reason contact admin",
          icon: "warning",
        });
      }
    } catch (e) {
      console.log(e);
      setLoader(false);
      swal({
        title: e,
        icon: "error",
      });
    }
  };

  return (
    <tr>
      <td className="d-flex align-items-center">
        <span className="ms-3 mt-2">{item.email}</span>
      </td>
      <td>{item.name}</td>
      <td>{item.address}</td>
      <td>{item.status ? "Active" : "Blocked"}</td>
      <td>{item.omc}</td>
      <td>{item.owner_phone}</td>
      <td>{item.manager_phone}</td>
      <td>{item.city}</td>
      <td>{item.state}</td>
      {user.type === "admin" && (
        <td>{item.canUseAll ? "Approved" : "Not Approved"}</td>
      )}
      <td>
        <img onClick={() => edithandleShow(item)} src={edit} alt="edit" />
      </td>
      <td>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button {...bindTrigger(popupState)}>
                <img src={moreIcon} alt="" />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => detailhandleShow()}>Details</MenuItem>
                <MenuItem onClick={() => changestatus(item)}>
                  {item.status ? "Block" : "Un-Block"}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    deleteUser(item);
                  }}
                >
                  Delete
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    changeapproveStatus(item);
                  }}
                >
                  {item.canUseAll ? "Un-Approve" : "Approve"}
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </td>

      <EditPumpModal
        editshow={editshow}
        edithandleClose={edithandleClose}
        defaultData={item}
        setLoader={setLoader}
        updatePump={updatePump}
      />

      <Detailpumpmodal
        detaildata={item}
        detailshow={detailshow}
        detailhandleClose={detailhandleClose}
      />
    </tr>
  );
}
