import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import api from "../../apis";
import swal from "sweetalert";

export default function Edit({
  show,
  setShow,
  defaultData,
  setLoader,
  fetchData,
}) {
  console.log("defaultData", defaultData);
  const user = useSelector((state) => state.authedUser.authedUser);
  const [data, setData] = useState({
    name: defaultData.name,
    unit: defaultData.unit,
    sellingrate: defaultData.sellingrate,
  });
  const validator = () => {
    if (!data.name) {
      return "Name is required";
    }
    if (!data.unit) {
      return "Unit is required";
    }
    if (!data.sellingrate) {
      return "Selling rate is required";
    }
    return false;
  };
  const handleNameChange = (event) => {
    setData({ ...data, name: event.target.value });
  };

  const handleUnitChange = (event) => {
    setData({ ...data, unit: event.target.value });
  };

  const handlesellingChange = (event) => {
    setData({
      ...data,
      sellingrate: event.target.value ? parseFloat(event.target.value) : 0,
    });
  };

  const saveDetails = async () => {
    try {
      setLoader(true);
      if (validator()) {
        throw new Error(validator());
      } else {
        if (localStorage.getItem("token")) {
          data.sellingrate = parseFloat(data.sellingrate);

          if (user.type == "admin" || (user.type == "pump" && user.canUseAll)) {
            setLoader(true);
            if (validator()) {
              throw new Error(validator());
            } else {
              if (localStorage.getItem("token")) {
                let response = await api.update_products({
                  _id: defaultData._id,
                  ...data,
                });

                if (!response) {
                  throw new Error("Error while updating product");
                }

                // const ratePayload = {
                //   rate: data.rate,
                //   productId: data._id,
                // };
                // const rateResponse = await createRate(ratePayload);

                // if (!rateResponse) {
                //   throw new Error("Error while editing product");
                // }

                await fetchData();
                setShow(false);
                //alert("Product Update Successfull.");
                swal({
                  title: "PRODUCT UPDATE SUCCESSFULLY !",
                  // text: "You clicked the button!",
                  icon: "success",
                });
              }
            }
          }
          setLoader(false);
        } else {
          swal({
            title: "You are blocked due to some reason contact admin",

            icon: "warning",
          });
        }
      }
    } catch (e) {
      console.log(e);
      // alert(e);
      swal({
        title: e,

        icon: "warning",
      });
      setLoader(false);
    }
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} centered={true}>
      <Modal.Body>
        <div className="modalForm">
          <div className="closeIco" onClick={() => setShow(false)}>
            <i className="bi bi-x"></i>
          </div>
          <h3 className="">Edit Product</h3>
          <div className="form-group">
            <label>Name *</label>
            <input
              value={data.name}
              type="text"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Name"
              onChange={handleNameChange}
            />
          </div>

          <div className="form-group">
            <label>Selling Rate *</label>
            <input
              value={data.sellingrate}
              type="text"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Selling Rate"
              onChange={handlesellingChange}
            />
          </div>
          <div className="form-group">
            <label>select unit *</label>
            <select
              className="form-control theme-bg-color border-0 "
              name=""
              id=""
              onChange={handleUnitChange}
            >
              <option selected disabled>
                Select Unit
              </option>

              <option value="ltr">Litre</option>
              <option value="kg">Per Box</option>
            </select>
          </div>
          <div className="text-center subBtn">
            <button
              class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10"
              onClick={saveDetails}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
