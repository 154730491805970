import React from "react";
import Modal from "react-bootstrap/Modal";

export default function CompanyDetailModal({
  detailhandleClose,
  detailshow,
  detailData,
}) {
  return (
    <Modal show={detailshow} onHide={detailhandleClose} centered={true}>
      <Modal.Body>
        <div className="modalForm">
          <div className="closeIco" onClick={detailhandleClose}>
            <i className="bi bi-x"></i>
          </div>
          <h3 className="">Company Details</h3>
          <div className="form-group">
            <label>Account ID *</label>
            <input
              value={detailData?.company_custom_id}
              type="number"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label>Name *</label>
            <input
              value={detailData?.name}
              type="text"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label>Email address *</label>
            <input
              value={detailData?.email}
              type="email"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
            <small id="emailHelp" className="form-text text-muted">
              We'll never share your email with anyone else.
            </small>
          </div>
          <div className="form-group">
            <label>Address *</label>
            <input
              value={detailData?.address}
              type="text"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label>Phone *</label>
            <input
              value={detailData?.phone}
              type="number"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label>pump *</label>
            <input
              value={detailData?.pump?.name}
              type="text"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label>Payment Type *</label>
            <input
              value={detailData?.billing_type}
              type="text"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label>Billing Cycle *</label>
            <input
              value={detailData?.billing_cycle}
              type="text"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label>Set Limit *</label>
            <input
              value={detailData?.limit}
              type="number"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label>Grace Time (in days) *</label>
            <input
              value={detailData?.lockDays}
              type="number"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
