import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import api from "../../apis/index";
import { v4 as uuidv4 } from "uuid";
import Loader from "../loader";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import PumpRow from "./pumprow";
import searchIcon from "../../images/search.svg";
import cities from "./city.json";

function Pump() {
  const authedUser = useSelector((state) => state.authedUser);

  const user = authedUser.authedUser;

  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);

  const [pumps, setPumps] = useState([]);

  const [data, setData] = useState({
    name: "",
    email: "",
    address: "",
    omc: "",
    owner_phone: "",
    manager_phone: "",
    city: "",
    state: "",
  });
  // const [open, setOpen] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleNameChange = (event) => {
    setData({ ...data, name: event.target.value });
  };
  const handleemailChange = (event) => {
    setData({ ...data, email: event.target.value });
  };
  const handleadressChange = (event) => {
    setData({ ...data, address: event.target.value });
  };
  const handleomcChange = (event) => {
    setData({ ...data, omc: event.target.value });
  };
  const handleptclChange = (event) => {
    setData({ ...data, manager_phone: event.target.value });
  };
  const handlephoneChange = (event) => {
    setData({ ...data, owner_phone: event.target.value });
  };
  const handlecityChange = (event) => {
    setData({ ...data, city: event.target.value });
  };
  const handlestateChange = (event) => {
    setData({ ...data, state: event.target.value });
  };

  const validator = () => {
    if (!data.name) {
      return "Name is required";
    }
    if (!data.email) {
      return "Email is required";
    }
    if (!data.address) {
      return "Address is required";
    }
    if (!data.owner_phone) {
      return "Phone is required";
    }
    if (data.owner_phone && isNaN(data.owner_phone)) {
      return "Phone is not correctly formatted";
    }
    let ph = data.owner_phone.toString();

    if (ph[0] !== "0" || ph[1] !== "3") {
      return "Phone number must start from 03";
    }

    if (data.owner_phone.length !== 11) {
      return "Phone is not correctly formatted";
    }
    return false;
  };

  const addfunc = async () => {
    try {
      if (user.type == "admin") {
        setLoader(true);

        if (validator()) {
          throw new Error(validator());
        } else {
          if (localStorage.getItem("token")) {
            console.log("User type", user.type);
            console.log("User data", user.data);

            function generateot() {
              var randomstring = "";
              var characters =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
              for (var i = 0; i < 40; i++) {
                randomstring += characters.charAt(
                  Math.floor(Math.random() * characters.length)
                );
              }
              return randomstring;
            }
            var onetimepass = generateot();

            const payload = {
              phone: data.owner_phone,
              type: "pump",
              email: data.email,
              name: data.name,
              address: data.address,
              omc: data.omc,
              owner_phone: data.owner_phone,
              manager_phone: data.manager_phone,
              state: data.state,
              city: data.city,
              otpass: onetimepass,
              companystaff: true,
            };

            // let response = await register_companies(data);

            let response = await api.registerpump(payload);

            console.log(response);

            if (!response.success) {
              throw new Error(response.message);
            }
            handleClose();
            setData({});
            fetchPumps();
            // fetchData();
            //alert("Registeration Successfull.");
            swal({
              title: "ADDED SUCCESSULLY !",
              // text: "You clicked the button!",
              icon: "success",
            });
          }
        }
        setLoader(false);
      } else {
        swal({
          title: "You are blocked due to some reason contact admin",

          icon: "warning",
        });
      }
    } catch (e) {
      console.log(e);
      // alert(e);
      swal({
        title: e,
        icon: "warning",
      });

      setLoader(false);
    }
  };

  const updatePump = (p) => {
    setPumps(
      pumps.map((pmp) => {
        if (pmp._id === p._id) {
          return p;
        } else {
          return pmp;
        }
      })
    );
  };
  useEffect(() => {
    fetchPumps();
  }, []);
  const fetchPumps = async (filter = "") => {
    try {
      setLoader(true);
      const response = await api.getpumpsforcompany({
        type: "pump",
        email: { $regex: filter, $options: "i" },
      });
      console.log(response.message);
      setPumps(response.message);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };
  const itemsperpages = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(pumps.length / itemsperpages);

  const itemsInCurrentPage = pumps.slice(
    (currentPage - 1) * itemsperpages,
    currentPage * itemsperpages
  );

  const filteredPumps = () => {
    let filter = document.getElementById("pump-filter-email").value
      ? document.getElementById("pump-filter-email").value
      : "";
    fetchPumps(filter);
  };

  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
        {/* Content Row */}
        <div className="row">
          {/* Area Chart */}
          <div className="col ">
            <div className=" card sortby-box br-10 border-transparent">
              {/* Card Header - Dropdown */}
              <div className="card-header br-10  bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
                <form className="d-none d-sm-inline-block form-inline me-auto ms-md-3 my-2 my-md-0 mw-100 navbar-search">
                  <h6>Filters</h6>
                  <div className="input-group">
                    <input
                      type="text"
                      id="pump-filter-email"
                      className="form-control theme-bg-color border-0 small"
                      placeholder="Enter Email"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                    />
                    <div className="input-group-append">
                      <button
                        onClick={filteredPumps}
                        className="btn search-btn pr-4"
                        type="button"
                      >
                        <img
                          src={searchIcon}
                          className="w-15 mb-1"
                          alt="serach icon"
                        />
                      </button>
                    </div>
                  </div>
                </form>
                <a
                  className=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 fs-12"
                  onClick={() => handleShow()}
                >
                  <span className="fs-14">+</span> Add New Pump
                </a>
              </div>
            </div>
            {/* table */}

            <div className="card sortby-box br-10 border-transparent mt-3">
              <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 d-flex flex-column justify-content-center">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th className="">Pump Email</th>
                      <th className="">Title</th>
                      <th className="">Address</th>
                      <th className="">Status</th>
                      <th className="">OMC</th>
                      <th className="">Owner Phone</th>
                      <th className="">Manager Phone</th>
                      <th className="">City</th>
                      <th className="">State</th>
                      {user.type === "admin" && <th className="">Approved</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {typeof pumps !== "string" &&
                      pumps.map((item) => (
                        <PumpRow
                          fetchPumps={fetchPumps}
                          setLoader={setLoader}
                          item={item}
                          key={uuidv4()}
                          updatePump={updatePump}
                        />
                      ))}
                  </tbody>
                </table>

                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        Prev
                      </button>
                    </li>
                    {[...Array(totalPages)].map((_, index) => (
                      <li
                        key={index + 1}
                        className={`page-item ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`page-item ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>

              {/* <EditModal open={editModal} setOpen={closeModal} /> */}
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered={true}>
        <Modal.Body>
          <div className="modalForm">
            <div className="closeIco" onClick={handleClose}>
              <i className="bi bi-x"></i>
            </div>
            <h3 className="">Add New Pump</h3>
            <div className="form-group">
              <label>Name *</label>
              <input
                placeholder="Enter Name"
                onChange={handleNameChange}
                type="text"
                className="form-control theme-bg-color border-0 small"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div className="form-group">
              <label>Email address *</label>
              <input
                placeholder="Enter Email"
                onChange={handleemailChange}
                type="email"
                className="form-control theme-bg-color border-0 small"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
              <small id="emailHelp" className="form-text text-muted">
                We'll never share your email with anyone else.
              </small>
            </div>
            <div className="form-group">
              <label>Address *</label>
              <input
                placeholder="Enter Address"
                onChange={handleadressChange}
                type="text"
                className="form-control theme-bg-color border-0 small"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>

            <div className="form-group">
              <label>Oil Marketing Company *</label>
              <input
                placeholder="Enter OMC"
                onChange={handleomcChange}
                type="text"
                className="form-control theme-bg-color border-0 small"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>

            <div className="form-group">
              <label>PTCL *</label>
              <input
                placeholder="Enter PTCL"
                onChange={handleptclChange}
                type="number"
                className="form-control theme-bg-color border-0 small"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>

            <div className="form-group">
              <label>Contact Number *</label>
              <input
                placeholder="Enter Contact Number"
                onChange={handlephoneChange}
                type="text"
                className="form-control theme-bg-color border-0 small"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>

            <div className="form-group">
              <label>City *</label>
              <select
                onChange={handlecityChange}
                className="form-control theme-bg-color border-0 "
                name=""
                id=""
              >
                <option value="" selected disabled>
                  Select City
                </option>
                {typeof cities !== "string" &&
                  cities.map((item) => (
                    <option value={item.city_name[0]}>
                      {item.city_name[0]}
                    </option>
                  ))}
              </select>
            </div>

            <div className="form-group">
              <label>State *</label>
              <select
                onChange={handlestateChange}
                className="form-control theme-bg-color border-0 "
                name=""
                id=""
              >
                <option value="" selected disabled>
                  Select State
                </option>

                <option value="sindh">Sindh</option>
                <option value="baclochistan">Balochistan</option>
                <option value="punjab">Punjab</option>
                <option value="kpk">Khyber Pakhtunkhwa</option>
              </select>
            </div>

            <div className="text-center subBtn">
              <button
                class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10"
                onClick={addfunc}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* /.container-fluid */}
      {loader && <Loader />}
    </>
  );
}

export default Pump;
