import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";

import { useSelector } from "react-redux";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";

import revenue from "../../images/revenue.svg";
import truck from "../../images/truck.svg";
import loadMove from "../../images/load-move.svg";
import swal from "sweetalert";
import kebab from "../../images/kebab.svg";
import profile from "../../images/profile.jpg";

import Modal from "react-bootstrap/Modal";
import Loader from "../loader";

import HRimage from "../imageupload/hr";
import api from "../../apis";
// import axios from "axios";

// js

// import Navbar from './navbar';
// import Sidebar from './sidebar';

function Dashboard(props) {
  const redColor = {
    color: "red",
  };
  const greenColor = {
    color: "#28CB89",
  };
  const authedUser = useSelector((s) => s.authedUser);
  const user = authedUser.authedUser;

  console.log("usernew", user);
  console.log(Object.keys(authedUser.authedUser).length > 0);

  const [editshow, seteditShow] = useState(false);

  const [payment, setpayment] = useState();

  const [pumpcount, setPumpcount] = useState(0);
  const [companycount, setCompanycount] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [staffCount, setStaffCount] = useState(0);
  const [vehicle, setVehicle] = useState(0);

  const [pumps, setPumps] = useState({ message: [] });

  const [vehicleData, setVehicleData] = useState({ message: [] });

  const [companyData, setCompanyData] = useState({ message: [] });

  const [loader, setLoader] = useState(false);

  const [imgdata, setImgdata] = useState({});
  const [data, setData] = React.useState({
    month: "",
    plan: "",
    description: "",
  });
  const edithandleClose = () => seteditShow(false);
  const edithandleShow = () => {
    seteditShow(true);
    // setPumpdata(param)
  };
  const handleMonthChange = (event) => {
    setData({ ...data, month: event.target.value });
  };
  const handlePlanChange = (event) => {
    setData({ ...data, plan: event.target.value });
  };
  const handleDescriptionChange = (event) => {
    setData({ ...data, description: event.target.value });
  };

  useEffect(() => {
    if (user.type == "pump") {
      fetchreceipt();
    }
  }, []);

  useEffect(() => {
    if (user.type == "admin") {
      console.log("usertype", user.type);

      fetchusercount();
    }
    if (user.type == "company") {
      fetchusercountforCompany();
    }
    if (user.type == "pump") {
      fetchusercountforPump();
    }
  }, []);

  const fetchusercount = async () => {
    try {
      const pumpcount = await api.usercount({ type: "pump" });
      const vehiclecount = await api.usercount({ type: "vehicle" });
      const companycount = await api.usercount({ type: "company" });
      const productcount = await api.productcount({});

      const response = await api.getpumpsforcompany({ type: "pump" });

      console.log("apires", response);
      if (response.success) {
        setPumps(response);
      } else {
        setPumps([]);
      }

      setPumpcount(pumpcount.message);
      setCompanycount(companycount.message);
      setVehicle(vehiclecount.message);
      setProductCount(productcount.message);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchusercountforCompany = async () => {
    try {
      const vehiclecount = await api.usercount({
        type: "vehicle",
        company: user._id,
      });
      const response = await api.getpumpsforcompany({
        type: "vehicle",
        company: user._id,
      });
      console.log("redColor", response);
      if (response.success) {
        setVehicleData(response);
      }
      setVehicle(vehiclecount.message);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchusercountforPump = async () => {
    try {
      const vehiclecount = await api.usercount({
        type: "vehicle",
        pump: user._id,
      });
      const companycount = await api.usercount({
        type: "company",
        pump: user._id,
      });
      const staffcount = await api.usercount({ type: "staff", pump: user._id });
      const productcount = await api.productcount({});
      const response = await api.getpumpsforcompany({
        type: "company",
        pump: user._id,
      });
      if (response.success) {
        setCompanyData(response);
      }
      setPumpcount(pumpcount.message);
      setCompanycount(companycount.message);
      setProductCount(productcount.message);
      setStaffCount(staffcount.message);
      setVehicle(vehiclecount.message);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchreceipt = async () => {
    try {
      const data = {
        pumpid: user._id,
      };
      // const response = await getreceipt(data);

      const response = await api.findpumpreceipt(data);
      setpayment(response);
    } catch (e) {
      console.log(e);
    }
  };

  const validator = () => {
    if (!data.month) {
      return "Month is required";
    }
    if (!data.plan) {
      return "Plan is required";
    }
    if (!data.description) {
      return "Desciption is required";
    }
    if (!imgdata.hrimageData) {
      return "Receipt is required";
    }
    return false;
  };

  const addfunc = async () => {
    try {
      if (validator()) {
        throw new Error(validator());
      } else {
        setLoader(true);
        console.log("hello");
        var date = new Date();
        date.setDate(date.getDate() + 30 * parseInt(data.month));
        console.log(date);
        const payloadd = {
          month: data.month,
          amount: parseInt(data.plan) * parseInt(data.month),
          pumpid: user._id,
          receiptImg: imgdata.hrimageData,
          receiptFormat: imgdata.hrimageFormat,
          payment: true,
          end_date: date,
          description: data.description,
          payemnt_status: "pending",
        };
        console.log("apiayload", payloadd);
        console.log("apiayload", payloadd);
        const response = await api.createpumpreceipt(payloadd);
        if (response.success) {
          throw new Error("Bug");
        }
        swal({
          title: "ADDED SUCCESSFULLY !",
          icon: "success",
        });
        setData({});
        setLoader(false);
      }
    } catch (e) {
      console.log(e);
      swal({
        title: e,
        icon: "warning",
      });
      setLoader(false);
    }
  };

  if (Object.keys(authedUser.authedUser).length > 0) {
    return (
      <>
        {/* Begin Page Content */}
        <div className="container-fluid">
          {/* Content Row */}

          {user.type == "admin" && (
            <div className="row">
              {/* Earnings (Monthly) Card Example */}
              {/* <AnalyticsBox icon={revenue} title="Total Revenue" percent="+ 20%" amount="$112,705" lastMonthAmount="Over last month: $15,600" /> */}
              <AnalyticsBox
                icon={loadMove}
                title="Number of Pump"
                color={redColor}
                amount={pumpcount}
                lastMonthAmount="Over last month: 2"
              />
              <AnalyticsBox
                icon={truck}
                title="Number of Companies"
                amount={companycount}
                lastMonthAmount="Over last month: 2"
              />
              <AnalyticsBox
                icon={truck}
                title="Total Vehicle"
                amount={vehicle}
                lastMonthAmount="Over last month: 54"
              />
              <AnalyticsBox
                icon={truck}
                title="Total Products"
                amount={productCount}
                lastMonthAmount="Over last month: 54"
              />
            </div>
          )}

          {user.type == "company" && (
            <div className="row">
              {/* Earnings (Monthly) Card Example */}
              {/* <AnalyticsBox icon={revenue} title="Total Revenue" percent="+ 20%" amount="$112,705" lastMonthAmount="Over last month: $15,600" /> */}
              <AnalyticsBox
                icon={truck}
                title="Total Vehicle"
                amount={vehicle}
                lastMonthAmount="Over last month: 54"
              />
            </div>
          )}

          {user.type == "pump" && (
            <div className="row">
              {/* Earnings (Monthly) Card Example */}
              {/* <AnalyticsBox icon={revenue} title="Total Revenue" percent="+ 20%" amount="$112,705" lastMonthAmount="Over last month: $15,600" /> */}
              <AnalyticsBox
                icon={truck}
                title="Number of Companies"
                amount={companycount}
                lastMonthAmount="Over last month: 2"
              />
              <AnalyticsBox
                icon={truck}
                title="Total Vehicle"
                amount={vehicle}
                lastMonthAmount="Over last month: 54"
              />
              <AnalyticsBox
                icon={truck}
                title="Total Staff"
                amount={staffCount}
                lastMonthAmount="Over last month: 54"
              />
              <AnalyticsBox
                icon={truck}
                title="Total Product"
                amount={productCount}
                lastMonthAmount="Over last month: 54"
              />
            </div>
          )}

          {/* Content Row */}
          <div className="row">
            {/* Area Chart */}
            <div className="col-xl-8 col-lg-7 mb-4">
              <div className="card br-10 h-100 border-transparent overflow-hidden">
                {/* Card Header - Dropdown */}
                <div className="card-header bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 chart-heading">Revenue Generated</h6>
                  <div className="dropdown no-arrow">
                    <a href="#" className="chart-time chart-time-active pr-4">
                      All time
                    </a>
                    <a href="#" className="chart-time pr-4">
                      This year
                    </a>
                    <a href="#" className="chart-time pr-4">
                      This week
                    </a>

                    <a
                      className="dropdown-toggle px-2"
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img src={kebab} className="w-4" alt="dropdown icon" />
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                      <div className="dropdown-divider" />
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                {/* Card Body */}
                <div className="card-body">
                  <div className="chart-area">
                    <RevenueChart />
                  </div>
                </div>
              </div>
            </div>

            {/* diver status */}
            <div className="col-xl-4 col-lg-5 mb-4">
              <div className="card h-100 mb-4 br-10 border-transparent overflow-hidden">
                {/* Card Header - Dropdown */}

                {user.type == "admin" && (
                  <div className="card-header bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 diver-name">Pumps's Name</h6>
                    <div>
                      <span className="driver-status-title">Status</span>
                    </div>
                  </div>
                )}
                {user.type == "pump" && (
                  <div className="card-header bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 diver-name">Company's Name</h6>
                    <div>
                      <span className="driver-status-title">Status</span>
                    </div>
                  </div>
                )}
                {user.type == "company" && (
                  <div className="card-header bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 diver-name">Vehicle's Name</h6>
                    <div>
                      <span className="driver-status-title">Status</span>
                    </div>
                  </div>
                )}

                {/* Card Body */}

                {user?.type == "admin" && (
                  <div className="card-body ">
                    {pumps?.message?.map((pump, index) => (
                      <DriverStatusRow
                        key={pump._id}
                        profileImg={profile}
                        driverName={pump?.name}
                        company={pump?.address}
                        status={pump?.canUseAll ? "Approve" : "Un approved"}
                      />
                    ))}

                    {/* <DriverStatusRow profileImg={profile} driverName={pumps?.message[1]?.name} company={pumps?.message[1]?.address} status={pumps?.message[1]?.canUseAll ? "Approve" : "Un approved"} />

                      <DriverStatusRow profileImg={profile} driverName={pumps?.message[2]?.name} company={pumps?.message[2]?.address} status={pumps?.message[2]?.canUseAll ? "Approve" : "Un approved"} />

                      <DriverStatusRow profileImg={profile} driverName={pumps?.message[3]?.name} company={pumps?.message[3]?.address} status={pumps?.message[3]?.canUseAll ? "Approve" : "Un approved"} />

                      <DriverStatusRow profileImg={profile} driverName={pumps?.message[4]?.name} company={pumps?.message[4]?.address} status={pumps?.message[4]?.canUseAll ? "Approve" : "Un approved"} />
 */}
                  </div>
                )}

                {user.type == "company" && (
                  <div className="card-body ">
                    {vehicleData?.message?.map((vehicle, index) => (
                      <DriverStatusRow
                        key={vehicle._id}
                        profileImg={profile}
                        driverName={vehicle?.name}
                        company={vehicle?.address}
                        status={vehicle?.canUseAll ? "Approve" : "Un approved"}
                      />
                    ))}

                    {/* <DriverStatusRow profileImg={profile} driverName={vehicleData?.message[1]?.name} company={vehicleData?.message[1]?.address} status={vehicleData?.message[1]?.canUseAll ? "Approve" : "Un approved"} />

                      <DriverStatusRow profileImg={profile} driverName={vehicleData?.message[2]?.name} company={vehicleData?.message[2]?.address} status={vehicleData?.message[2]?.canUseAll ? "Approve" : "Un approved"} />

                      <DriverStatusRow profileImg={profile} driverName={vehicleData?.message[3]?.name} company={vehicleData?.message[3]?.address} status={vehicleData?.message[3]?.canUseAll ? "Approve" : "Un approved"} />

                      <DriverStatusRow profileImg={profile} driverName={vehicleData?.message[4]?.name} company={vehicleData?.message[4]?.address} status={vehicleData?.message[4]?.canUseAll ? "Approve" : "Un approved"} /> */}
                  </div>
                )}

                {user.type == "pump" && (
                  <div className="card-body ">
                    {companyData?.message?.map((company, index) => (
                      <DriverStatusRow
                        key={company._id}
                        profileImg={profile}
                        driverName={company?.name}
                        company={company?.address}
                        status={company?.canUseAll ? "Approve" : "Un approved"}
                      />
                    ))}
                    {/* <DriverStatusRow profileImg={profile} driverName={companyData?.message[1]?.name} company={companyData?.message[1]?.address} status={companyData?.message[1]?.canUseAll ? "Approve" : "Un approved"} />

                      <DriverStatusRow profileImg={profile} driverName={companyData?.message[2]?.name} company={companyData?.message[2]?.address} status={companyData?.message[2]?.canUseAll ? "Approve" : "Un approved"} />

                      <DriverStatusRow profileImg={profile} driverName={companyData?.message[3]?.name} company={companyData?.message[3]?.address} status={companyData?.message[3]?.canUseAll ? "Approve" : "Un approved"} />

                      <DriverStatusRow profileImg={profile} driverName={companyData?.message[4]?.name} company={companyData?.message[4]?.address} status={companyData?.message[4]?.canUseAll ? "Approve" : "Un approved"} /> */}
                  </div>
                )}
              </div>
            </div>

            {user?.type == "pump" &&
              !user?.canUseAll &&
              !payment?.payment &&
              user?.type != "admin" && (
                <div className="col-xl-3 col-lg-4 mb-4 ">
                  <div className="card h-100 mb-4 br-10 border-transparent overflow-hidden">
                    {/* Card Header - Dropdown */}
                    <div className="card-header bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 diver-name">Payments</h6>
                    </div>
                    {/* Card Body */}
                    <div className="card-body d-flex">
                      <button
                        class="btn border-transparent loadBtnColor loadBtnBg justify-content-center font-weight-bold br10"
                        onClick={() => edithandleShow()}
                      >
                        Add Pump Payments
                      </button>
                    </div>
                  </div>
                </div>
              )}

            {user?.type == "pump" &&
              !user?.canUseAll &&
              payment?.payment &&
              user?.type != "admin" && (
                <div className=" col-xl-4 col-lg-4 mb-4 ">
                  <div className="card h-100 mb-4 br-10 border-transparent overflow-hidden">
                    {/* Card Header - Dropdown */}
                    <div className="card-header bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 diver-name">Payments</h6>
                    </div>
                    {/* Card Body */}
                    <div className="card-body">
                      <h5>Check status of your receipt!!</h5>

                      <h6>Note: if rejected kindly edit your details !</h6>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
        {/* /.container-fluid */}
        <Modal show={editshow} onHide={edithandleClose} centered={true}>
          <Modal.Body>
            <div className="modalForm">
              <div className="closeIco" onClick={edithandleClose}>
                <i className="bi bi-x"></i>
              </div>
              <h3 className="">Add New Receipts</h3>
              <div className="form-group">
                <label>pump</label>
                <select
                  onChange={handlePlanChange}
                  className="form-control theme-bg-color border-0 "
                  name=""
                  id=""
                >
                  <option value="" selected disabled>
                    {" "}
                    Select Plan{" "}
                  </option>
                  <option value="10000"> Gold </option>
                </select>
              </div>

              <div className="form-group">
                <label>Months</label>
                <input
                  type="number"
                  className="form-control theme-bg-color border-0 small"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Months"
                  onChange={handleMonthChange}
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <input
                  onChange={handleDescriptionChange}
                  type="text"
                  className="form-control theme-bg-color border-0 small"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Description"
                />
              </div>
              <div className="form-group">
                <label>Upload Image</label>
                <HRimage setImgdata={setImgdata} />
              </div>

              <div className="text-center subBtn">
                <button
                  class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10"
                  onClick={() => addfunc()}
                >
                  Submit
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {loader && <Loader />}
      </>
    );
  } else {
    return <Navigate push to="/login" />;
  }
}

function AnalyticsBox(props) {
  return (
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card h-100 py-2 border-transparent br-10">
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col">
              <img className="analytic-box-img" src={props.icon} alt="" />
            </div>
            <div className="col-auto me-2">
              <div className="text-xs font-weight-bold  mb-1">
                <span className="analytics-box-title">{props.title}</span>{" "}
                <span className="analytic-percentage ms-2" style={props.color}>
                  {props.percent}
                </span>
              </div>
              <div className="h4 mb-0 font-weight-bold text-gray-800">
                {props.amount}
                <span className="mile">{props.mile}</span>
              </div>
              <div>
                <span className="last-month">{props.lastMonthAmount}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DriverStatusRow(props) {
  return (
    <div className="d-flex py-2 driver-dash-div flex-row align-items-center justify-content-between">
      <div className="d-flex">
        <img className="driver-img rounded-circle" src={props.profileImg} />
        <div className="profile-div ms-2">
          <span className="me-2 fs-14 small profile-name">
            {props.driverName}
          </span>
          <span className="status fs-10 font-weight-bold dark-font">
            {props.company}
          </span>
        </div>
      </div>
      <div>
        {/* this condition check status and set specified color */}
        {props.status == "Loaded" && props.status != "Waiting" ? (
          <span className="driver-status-title status-loaded">
            {props.status}
          </span>
        ) : props.status == "Waiting" && props.status != "Loaded" ? (
          <span className="driver-status-title status-waiting">
            {props.status}
          </span>
        ) : (
          <span className="driver-status-title status-offline">
            {props.status}
          </span>
        )}
      </div>
    </div>
  );
}

function RevenueChart() {
  const data = [
    {
      name: "Jan",
      uv: 40,
      pv: 24,
      amt: 2400,
    },
    {
      name: "Feb",
      uv: 10,
      pv: 13,
      amt: 2210,
    },
    {
      name: "Mar",
      uv: 90,
      pv: 98,
      amt: 2290,
    },
    {
      name: "Apr",
      uv: 120,
      pv: 110,
      amt: 2000,
    },

    {
      name: "May",
      uv: 100,
      pv: 90,
      amt: 2500,
    },
    {
      name: "Jun",
      uv: 34,
      pv: 43,
      amt: 2100,
    },
    {
      name: "Jul",
      uv: 34,
      pv: 43,
      amt: 2100,
    },
    {
      name: "Aug",
      uv: 34,
      pv: 43,
      amt: 2100,
    },
    {
      name: "Sep",
      uv: 44,
      pv: 53,
      amt: 2100,
    },
    {
      name: "Oct",
      uv: 84,
      pv: 93,
      amt: 2100,
    },
    {
      name: "Nov",
      uv: 44,
      pv: 43,
      amt: 2100,
    },
    {
      name: "Dec",
      uv: 34,
      pv: 53,
      amt: 2100,
    },
  ];
  return (
    // <ResponsiveContainer width="100%" height="100%">
    <LineChart width={600} height={300} data={data} className="w-100 h-100">
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
      <YAxis />
      <Tooltip />
      <Line
        type="monotone"
        dataKey="pv"
        stroke="#e0262a"
        stroke-width="2"
        activeDot={{ r: 8 }}
      />
      <Line type="monotone" dataKey="uv" stroke="#cad1da" stroke-width="2" />
    </LineChart>
    // </ResponsiveContainer>
  );
}

export { Dashboard };
