import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";

export default function Detail({ show, setShow, item }) {
  console.log("-0-", item);
  return (
    <Modal show={show} onHide={() => setShow(false)} centered={true}>
      <Modal.Body>
        <div className="modalForm">
          <div className="closeIco" onClick={() => setShow(false)}>
            <i className="bi bi-x"></i>
          </div>
          <h3 className="">HR Details</h3>
          <div className="form-group">
            <label>CNIC *</label>
            <input
              value={item?.cnic}
              type="number"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label>Name *</label>
            <input
              value={item?.name}
              type="text"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label>Description *</label>
            <input
              type="text"
              value={item?.description}
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label>Phone *</label>
            <input
              type="number"
              value={item?.phone}
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label>Stolen Amount *</label>
            <input
              type="number"
              value={item?.stolen}
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>

          <div className="form-group">
            <label>Pump *</label>
            <select
              className="form-control theme-bg-color border-0 "
              name=""
              id=""
            >
              <option disabled value="1" selected={true}>
                {item?.pump?.name}
              </option>
            </select>
          </div>

          <div className="text-center subBtn">
            <button class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10">
              Submit
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
